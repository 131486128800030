<template>
    <div>
        <VoteSection @error="error"
            @alert="alert"
            @preload="preload"
            @loaded="loaded"
            v-bind:title="lang('lbl_0000169')"
            v-bind:subtitle="lang('lbl_0000183')"
            v-bind:notice="lang('lbl_0000170')"
            v-bind:max="7"
            id="bod"
            category="bod"
            href="/ga/election/vote/audit"
            color="bg-theme-bod"
            backable />
    </div>
</template>

<script>
import VoteSection from './../../components/sections/ElectionVoteSection.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        VoteSection,
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', true);
        this.$emit('shownavbar', {
            show: false,
        });

        let state = this.$store.state.ga;

        if(!state.registered || state.voted) {
            this.$router.push('/dashboard');
        }
    },
    methods: {
        alert(config) {
            this.$emit('alert', config);
        },
        error(error) {
            this.$emit('error', error);
        },
        preload() {
            this.$emit('showloader');
        },
        loaded() {
            this.$emit('hideloader');
        }
    }
}
</script>