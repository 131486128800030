import { createStore } from 'vuex'

export default createStore({
  state: {
    login: false,
    down: false,
    uuid: null,
    key: null,
    qr: null,
    hash: null,
    active: false,
    has_pin: false,
    express: false,
    deactivated: false,
    deactivate_date: null,
    lang: "en",
    config: [],
    info: {
      first_name: null,
      last_name: null,
      middle_name: null,
      account_no: null,
      serial_no: null,
      username: null,
      membership_type: null,
      regular: false,
      mobile_no: null,
      email: null,
      tin: null,
      date_of_birth: null,
      age: null,
      gender: null,
      registered: null,
    },
    deactivate: {
      hash: null,
      otp: {
        pin: null,
        token: null,
      },
    },
    user_agent: {
      browser: {
        name: null,
        version: null,
      },
      device: {
        model: null,
        type: null,
        vendor: null,
      },
      engine: {
        name: null,
        version: null,
      },
      os: {
        name: null,
        version: null,
      },
    },
    forgot: {
      value: '',
      uuid: null,
      hash: null,
      pin: null,
      username: null,
      first_name: null,
      last_name: null,
      email: null,
      mobile: null,
      otp: {
        token: null,
        pin: null,
      }
    },
    registration: {
      last_name: '',
      first_name: '',
      account_no: '',
      serial_no: '',
      username: '',
      email: '',
      mobile_no: '',
      password: '',
      con_password: '',
      agree: false,
      hash: null,
      pin: null,
      mpin: null,
      done: false,
      session: null,
      uuid: null,
      otp: {
        mobile: {
          pin: null,
          token: null,
        },
        email: {
          pin: null,
          token: null,
        },
      },
    },
    ga: {
      code: null,
      title: null,
      registration: {
        start_date: null,
        started: false,
        end_date: null,
        ended: false,
      },
      voting: {
        start_date: null,
        started: false,
        end_date: null,
        ended: false,
      },
      mev: false,
      signed: false,
      can_vote: false,
      proxy_holder: false,
      total_votes: 0,
      voted: false,
      registered: false,
    },
    vote: {
      start_date: null,
      end_date: null,
      secret_key: null,
      voter_key: null,
      ballot: null,
      done: false,
    },
    branches: [],
    election: {
      ballot: null,
      voting_start: null,
      voting_started: false,
      voting_end: null,
      voting_ended: false,
      reg_start: null,
      reg_started: false,
      reg_end: null,
      reg_ended: false,
      registered: false,
      voted: false,
      signed: false,
      rated: false,
      mev: false,
      can_vote: false,
      started: false,
      done: false,
    },
    candidates: {
      bod: [],
      elecom_of: [],
      elecom_ep: [],
      audit: [],
    },
    selected: {
      bod: [],
      elecom_of: [],
      elecom_ep: [],
      audit: [],
    },
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
