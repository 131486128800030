<template>
    <div class="flex flex-col">
        <div class="flex px-4 py-4">
            <div v-for="n in length" 
                v-bind:key="n" 
                v-bind:class="{
                    'ml-0': n == 1,
                    'mr-0': n == length,
                }" 
                class="border border-grey flex-1 mx-1 flex items-center justify-center rounded bg-grey-lightest papsi-num-display">
                <DigitInputControl @focused="setFocus"
                    @typing="typing"
                    @next="next"
                    @prev="prev"
                    v-bind:focus="getFocused(n)"
                    v-bind:empty="getFocused(n)"
                    v-bind:value="getValue(n)"
                    v-bind:length="length"
                    v-bind:hidden="hidden"
                    v-bind:num="n" />
            </div>
        </div>
    </div>
</template>

<script>
import mixin from './../../mixin.js';
import DigitInputControl from './../../components/controls/DigitInputControl.vue';

export default {
    name: 'v-number-input',
    mixins: [
        mixin,
    ],
    components: {
        DigitInputControl,
    },
    props: {
        hidden: {
            type: Boolean,
            default: false,
        },
        length: {
            type: Number,
            default: 6,
        },
        value: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            digits: [],
            focused: 0,
        }
    },
    mounted() {
        for (let i = 1; i <= this.length; i++) {
            this.digits[i - 1] = null;
        }

        this.splitValue(this.value);
    },
    methods: {
        getName(n) {
            return "digit_" + n;
        },
        next(n) {
            this.focused = n.number;
        },
        prev(n) {
            this.focused = n.number;
        },
        getFocused(num) {
            return num == this.focused;
        },
        setFocus(num) {
            this.focused = num;
        },
        typing(obj) {
            this.digits[(obj.number - 1)] = obj.value;
            this.$emit('typing', this.getText());

            if (this.getText().length == this.length) 
            {
                this.$emit('complete', this.getText());
            }
        },
        getText() {
            let value = '',
                that = this;

            let index = 0;
            this.digits.forEach(function() {
                value += that.digits[index];
                index++;
            });

            return value;
        },
        getValue(num) {
            return this.digits[num - 1] || '';
        },
        splitValue(value) {
            let that = this;

            if (value.length != 0) {
                value.split('').forEach(function(item, index) {
                    that.digits[index] = item;
                });
            }
            else {
                this.digits = [];
            }
        },
    },
    watch: {
        value(val) {
            this.splitValue(val);
        },
    },
}
</script>

<style scoped>
    .papsi-num-display {
        min-height: 50px !important;
    }
</style>