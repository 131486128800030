<template>
    <div class="flex flex-col">
        <div class="px-4 pt-4">
            <div class="flex">
                <div class="font-bold text-md flex items-center justify-center leading-normal">
                    <span v-text="label"></span>
                </div>
                <div class="flex-grow"></div>
                <div v-on:click="peek" v-show="hint" class="flex items-center justify-center">
                    <img v-bind:src="getIcon()" />
                </div>
            </div>
        </div>
        <div>
            <NumberInputControl @complete="complete"
                @typing="typing"
                v-bind:length="length"
                v-bind:value="pin"
                v-bind:hidden="hidden" />
        </div>
    </div>
</template>

<script>
import NumberInputControl from './../../components/controls/NumberInputControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        NumberInputControl,
    },
    props: {
        label: {
            type: String,
        },
        hint: {
            type: Boolean,
            default: true,
        },
        value: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            length: 6,
            hidden: true,
            pin: '',
        }
    },
    mounted() {
        if (this.value != null) {
            this.pin = this.value;
        }
    },
    methods: {
        typing(obj) {
            this.$emit('typing', obj);
        },
        complete(value) {
            this.$emit('complete', value);
        },
        peek() {
            this.hidden = !this.hidden;
        },
        getIcon() {
            return require(!this.hidden ? "@/assets/icons/papsi_password_visible.svg" : "@/assets/icons/papsi_password_invisible.svg");
        },
    },
    watch: {
        value(value) {
            this.pin = value;
        },
    }
}
</script>