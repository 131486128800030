import { UAParser } from 'ua-parser-js';
import English from './locale/en.js';

export default {
    data() {
        return {
            locales: {
                en: English,
            }
        }
    },
    methods: {
        getMediaPath(path) {
            return process.env.VUE_APP_MEDIA_URL + 'images' + path;
        },
        lang(id, lang = null) {
            let store = this.$store.state,
                language = lang || store.lang,
                locale = this.locales[language],
                keys = Object.keys(locale);

                if(keys.indexOf(id) !== -1) {
                    return locale[id];
                }

            return id;
        },
        setUserAgent(userAgent) {
            let ua = new UAParser(userAgent),
                state = this.$store.state;

            state.user_agent.browser.name = ua.getBrowser().name;
            state.user_agent.browser.version = ua.getBrowser().version;
            state.user_agent.device.model = ua.getDevice().model;
            state.user_agent.device.type = ua.getDevice().type;
            state.user_agent.device.vendor = ua.getDevice().vendor;
            state.user_agent.engine.name = ua.getEngine().name;
            state.user_agent.engine.version = ua.getEngine().version;
            state.user_agent.os.name = ua.getOS().name;
            state.user_agent.os.version = ua.getOS().version;
        },
        setUserData(data) {
            let state = this.$store.state;

            state.login = true;
            state.uuid = data.uuid;
            state.key = data.key;
            state.qr = data.qr;
            state.hash = data.hash;
            state.active = data.active;
            state.has_pin = data.has_pin;
            state.express = data.express;
            state.deactivate_date = data.deactivated;
            state.deactivated = state.deactivate_date != null;
            state.info.first_name = data.info.first_name;
            state.info.last_name = data.info.last_name;
            state.info.middle_name = data.info.middle_name;
            state.info.account_no = data.info.account_number;
            state.info.serial_no = data.info.serial_number;
            state.info.username = data.info.username;
            state.info.membership_type = data.info.membership_type;
            state.info.regular = data.info.regular;
            state.info.mobile_no = data.info.mobile;
            state.info.email = data.info.email;
            state.info.tin = data.info.tin;
            state.info.date_of_birth = data.info.date_of_birth;
            state.info.age = data.info.age;
            state.info.gender = data.info.gender;
            state.info.registered = data.info.registered;

            data.config.forEach(function(item) {
                state.config.push(item);
            });
        },
        resetUserData() {
            let state = this.$store.state;

            state.login = false;
            state.uuid = null;
            state.key = null;
            state.qr = null;
            state.hash = null;
            state.active = false;
            state.has_pin = false;
            state.express = false;
            state.deactivate_date = null;
            state.deactivated = false;
            state.info.first_name = null;
            state.info.last_name = null;
            state.info.middle_name = null;
            state.info.account_no = null;
            state.info.serial_no = null;
            state.info.username = null;
            state.info.membership_type = null;
            state.info.regular = false;
            state.info.mobile_no = null;
            state.info.email = null;
            state.info.tin = null;
            state.info.date_of_birth = null;
            state.info.age = null;
            state.info.gender = null;
            state.info.registered = null;
            state.info.deactivated = false;
            state.info.deactivate_date = null;
            state.config.splice(0, state.config.length);
        },
        makeCookie(hash, expire) {
            if (!this.$cookies.isKey('hash')) {
                this.$cookies.set('hash', hash, expire ? (60 * 60 * 24) : 0);
            }
        },
        destroyCookie() {
            if (this.$cookies.isKey('hash')) {
                this.$cookies.remove('hash');
            }
        },
    }
}