<template>
    <div class="pt-4">
        <ContentWrapperControl>
            <SettingCardControl v-bind:title="lang('lbl_0000067')">
                <SettingCardItemControl v-bind:value="tin"
                    v-bind:title="lang('lbl_0000098')"
                    v-bind:href="tin_href"
                    icon="papsi_tax.svg" />
            </SettingCardControl>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import SettingCardControl from './../../components/controls/SettingCardControl.vue';
import SettingCardItemControl from './../../components/controls/SettingCardItemControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
        SettingCardControl,
        SettingCardItemControl,
    },
    data() {
        return {
            tin: null,
            tin_href: '/settings/info/tin',
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000081'),
            show: true,
            back: true,
        });

        this.tin = this.$store.state.info.tin;

        if (this.tin != null) {
            this.tin_href = null;
        }
    },
}
</script>