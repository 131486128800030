<template>
    <ContentWrapperControl>
        <div class="bg-white overflow-hidden mt-4 rounded shadow mb-4 leading-normal">
            <div class="p-6">
            <div class="mb-4">
                <TextControl @typing="typing"
                    v-bind:value="last_name"
                    v-bind:disabled="freezed"
                    v-bind:placeholder="lang('lbl_0000145')"
                    type="text" 
                    name="last_name" 
                    uppercase
                    required />
            </div>
            <div class="mb-4">
                <TextControl @typing="typing" 
                    v-bind:value="first_name"
                    v-bind:disabled="freezed"
                    v-bind:placeholder="lang('lbl_0000146')"
                    type="text" 
                    name="first_name" 
                    uppercase
                    required />
            </div>
            <div class="mb-4">
                <div class="flex">
                    <div class="flex-1 mr-3">
                        <TextControl @typing="typing" 
                            v-bind:value="account_no"
                            v-bind:disabled="freezed || serial_no.length != 0"
                            v-bind:placeholder="lang('lbl_0000093')"
                            :maxlength="6" 
                            type="text" 
                            name="account_no" 
                            uppercase />
                    </div>
                    <div class="flex-1">
                        <TextControl @typing="typing" 
                            v-bind:value="serial_no"
                            v-bind:disabled="freezed || account_no.length != 0"
                            v-bind:placeholder="lang('lbl_0000094')"
                            :maxlength="8" 
                            type="text" 
                            name="serial_no" 
                            uppercase />
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <TextControl @typing="typing" 
                    v-bind:value="mobile_no"
                    v-bind:disabled="freezed"
                    v-bind:placeholder="lang('lbl_0000007')"
                    type="mobile" 
                    name="mobile_no"
                    required />
            </div>
            <div class="mb-4">
                <TextControl @typing="typing"
                    v-bind:value="email"
                    v-bind:disabled="freezed"
                    v-bind:placeholder="lang('lbl_0000005')"
                    type="text" 
                    name="email"
                    required />
            </div>
            <div class="mb-4">
                <div>
                    <TextControl @typing="typing" 
                        v-bind:value="username"
                        v-bind:disabled="freezed"
                        v-bind:placeholder="lang('lbl_0000041')"
                        type="text" 
                        name="username"
                        required />
                </div>
                <div v-text="lang('lbl_0000053')" class="mt-1 text-xs text-grey-dark italic"></div>
            </div>
            <div class="mb-4">
                <div>
                    <TextControl @typing="typing" 
                        v-bind:value="password"
                        v-bind:disabled="freezed"
                        v-bind:placeholder="lang('lbl_0000042')"
                        type="password" 
                        name="password" 
                        required
                        strength />
                </div>
                <div v-text="lang('lbl_0000060')" class="mt-1 text-xs text-grey-dark italic"></div>
            </div>
            <div class="mb-4">
                <TextControl @typing="typing" 
                    @strong="strong"
                    v-bind:value="con_password"
                    v-bind:disabled="freezed"
                    v-bind:placeholder="lang('lbl_0000048')"
                    type="password" 
                    name="con_password"
                    required />
            </div>
            <div class="mb-4 text-xs leading-normal text-grey-darkest">
                <CheckboxControl @toggle="toggle" v-bind:disabled="freezed" v-bind:checked="agree">
                    <div>
                        {{ lang('lbl_0000147') }} <router-link to="/end-user-license-agreement" class="no-underline text-primary font-bold">
                            {{ lang('lbl_0000133') }}
                        </router-link>
                        {{ lang('and') }} <router-link to="/privacy-policy" class="no-underline text-primary font-bold">
                            {{ lang('lbl_0000135') }}
                        </router-link>.
                    </div>
                </CheckboxControl>
            </div>
            <div>
                <ButtonControl v-on:click="join" v-bind:disabled="disabled">
                    {{ lang('lbl_0000148') }}
                </ButtonControl>
            </div>
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import { onBeforeRouteLeave } from 'vue-router';
import ButtonControl from './../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../components/controls/ContentWrapperControl.vue';
import CheckboxControl from './../components/controls/CheckboxControl.vue';
import TextControl from './../components/controls/TextControl.vue';
import User from './../services/User.js';
import mixin from './../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        CheckboxControl,
        TextControl,
    },
    data() {
        return {
            last_name: '',
            first_name: '',
            account_no: '',
            serial_no: '',
            username: '',
            email: '',
            mobile_no: '',
            password: '',
            con_password: '',
            agree: false,
            disabled: true,
            freezed: false,
            passed: false,
            image: '',
        }
    },
    mounted() {
        this.$emit('checkauth', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000014'),
            show: true,
            back: true,
        });

        let state = this.$store.state.registration,
            that = this;

        this.last_name = state.last_name;
        this.first_name = state.first_name;
        this.account_no = state.account_no;
        this.serial_no = state.serial_no;
        this.username = state.username;
        this.email = state.email;
        this.mobile_no = state.mobile_no;
        this.password = state.password;
        this.con_password = state.con_password;
        this.agree = state.agree;
        this.image = this.getMediaPath('/cover/papsi_cover_family.jpg');

        onBeforeRouteLeave((to) => {
            let exclude = [
                    'create-account-page',
                    'create-account-verify-page',
                    'create-account-verify-mobile-page',
                    'create-account-verify-email-page',
                    'create-account-enroll-pin-page',
                    'create-account-confirm-pin-page',
                    'create-account-done-page',
                    'privacy-policy-page',
                    'end-user-license-agreement-page',
                ];

            if(exclude.indexOf(to.name) === -1) {
                let store = that.$store.state.registration;

                store.last_name = '';
                store.first_name = '';
                store.account_no = '';
                store.serial_no = '';
                store.username = '';
                store.email = '';
                store.mobile_no = '';
                store.password = '';
                store.con_password = '';
                store.agree = false;
            }
        });
    },
    methods: {
        strong(passed) {
            this.passed = passed;
        },
        toggle(n) {
            this.agree = n;
            this.$store.state.registration.agree = n;
            this.disableButton();
        },
        typing(obj) {
            this[obj.name] = obj.value;
            this.$store.state.registration[obj.name] = obj.value;
            this.disableButton();
        },
        disableButton() {
            this.disabled = (this.last_name.length == 0 ||
                this.first_name.length == 0 ||
                (this.account_no.length == 0 && this.serial_no.length == 0) ||
                this.username.length == 0 ||
                this.mobile_no.length == 0 ||
                this.password.length == 0 ||
                this.con_password.length == 0 ||
                !this.agree ||
                !this.passed
            );
        },
        join() {
            this.$emit('showloader');
            let that = this;

            User.validate({
                first_name: this.first_name,
                last_name: this.last_name,
                account_no: this.account_no.toUpperCase(),
                serial_no: this.serial_no.toUpperCase(),
                email: this.email,
                mobile_no: this.getMobile(),
                username: this.username,
                password: this.password,
                con_password: this.con_password,
                agreement: this.agree,
            }).then(function(response) {
                let data = response.data.data,
                    state = that.$store.state;
                
                    state.registration.hash = data.hash;
                    state.registration.pin = data.pin;
                    state.registration.otp.mobile.pin = data.otp.mobile.pin;
                    state.registration.otp.email.pin = data.otp.email.pin;

                    that.$router.push('/signup/verify');
                    that.$emit('hideloader');
            }).catch(function(error) {
                that.password = '';
                that.con_password = '';
                that.agree = false;
                that.$emit('error', error);
            });
        },
        getMobile() {
            return this.mobile_no.length != 0 ? '+63' + this.mobile_no : null;
        },
    }
}
</script>