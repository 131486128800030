<template>
    <div>
        <PromptControl @ok="ok"
            @closed="closed"
            @cancel="cancel"
            v-bind:show="prompt.show" 
            v-bind:title="prompt.title" 
            v-bind:message="prompt.message" />
        <ContentWrapperControl>
            <div class="rounded shadow mt-4 overflow-hidden bg-white">
                <div>
                    <PinControl @typing="typing" 
                        v-bind:label="getLabel()"
                        v-bind:value="value"
                        hint />
                </div>
                <div class="px-4 pb-4">
                    <ButtonControl v-on:click="next" v-bind:disabled="disabled">
                        {{ button_lbl }}
                    </ButtonControl>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from 'vue-router';
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import PinControl from './../../components/controls/PinControl.vue';
import PromptControl from './../../components/controls/PromptControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        PinControl,
        PromptControl,
    },
    data() {
        return {
            level: 0,
            value: '',
            button_lbl: '',
            old_pin: '',
            new_pin: '',
            disabled: true,
            changed: false,
            href: null,
            prompt: {
                show: false,
                title: null,
                message: null,
            },
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000087'),
            show: true,
            back: true,
        });

        let that = this;

        onBeforeRouteLeave((to) => {
            that.href = to.fullPath;
            console.log(that.changed);

            if(that.changed) {
                that.prompt.title = that.lang('lbl_0000056');
                that.prompt.message = that.lang('lbl_0000057');
                that.prompt.show = true;

                return false;
            }
        });

        this.button_lbl = this.lang('lbl_0000033');
    },
    methods: {
        ok() {
            this.changed = false;
            this.$router.push(this.href);
        },
        cancel() {
            this.prompt.show = false;
        },
        closed() {
            this.prompt.show = false;
        },
        typing(value) {
            this.value = value;
        },
        getLabel() {
            if (this.level == 1) {
                return this.lang('lbl_0000089');
            }
            else if (this.level == 2) {
                return this.lang('lbl_0000090');
            }
            else {
                return this.lang('lbl_0000088');
            }
        },
        next() {
            let that = this,
                state = this.$store.state;

            if (this.level == 1) {
                if (this.value == this.old_pin) {
                    this.value = '';
                    this.$emit('alert', {
                        theme: 'error',
                        title: this.lang('lbl_0000058'),
                        message: this.lang('lbl_0000159'),
                    });
                }
                else {
                    this.$emit('showloader');
                    User.testPin(this.value).then(function() {
                        that.new_pin = that.value;
                        that.level = 2;
                        that.value = '';
                        that.button_lbl = that.lang('lbl_0000050');
                        that.$emit('hideloader');
                    }).catch(function(error) {
                        that.level = 1;
                        that.value = '';
                        that.$emit('error', error);
                    });
                }
            }
            else if (this.level == 2) {
                if (this.new_pin != this.value) {
                    this.value = '';
                    this.$emit('alert', {
                        theme: 'error',
                        title: this.lang('lbl_0000058'),
                        message: this.lang('lbl_0000092'),
                    });
                }
                else {
                    this.$emit('showloader');
                    User.updatePin(state.uuid, this.old_pin, this.value).then(function() {
                        that.$store.state.has_pin = true;
                        that.reset();
                        that.$emit('success', that.lang('lbl_0000091'));
                    }).catch(function(error) {
                        that.level = 2;
                        that.value = '';
                        that.$emit('error', error);
                    });
                }
            }
            else {
                this.$emit('showloader');
                User.validatePin(state.uuid, this.value).then(function() {
                    that.old_pin = that.value;
                    that.changed = true;
                    that.level = 1;
                    that.value = '';
                    that.$emit('hideloader');
                }).catch(function(error) {
                    that.level = 0;
                    that.value = '';
                    that.$emit('error', error);
                });
            }
        },
        reset() {
            this.level = 0;
            this.value = '';
            this.new_pin = '';
            this.old_pin = '';
            this.disabled = true;
            this.changed = false;
            this.button_lbl = this.lang('lbl_0000033');
        },
    },
    watch: {
        value(value) {
            this.disabled = !(value.length == 6);
            this.changed = value.length != 0 || this.level != 0;
        },
    },
}
</script>