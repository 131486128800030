<template>
    <ContentWrapperControl>
        <div class="bg-white p-4 rounded-b shadow mb-4">
            <div v-text="lang('lbl_0000160')" class="text-sm text-grey-darkest leading-normal"></div>
        </div>
        <div class="bg-white p-4 rounded shadow">
            <div class="mb-4">
                <TextboxControl @typing="typing" 
                    v-bind:value="new_password" 
                    v-bind:placeholder="lang('lbl_0000047')"
                    type="password"
                    name="new_password"
                    required
                    strength />
            </div>
            <div class="mb-4">
                <TextboxControl @typing="typing" 
                    @strong="strong"
                    v-bind:value="con_password"
                    v-bind:placeholder="lang('lbl_0000048')"
                    type="password" 
                    name="con_password"
                    required />
            </div>
            <div>
                <ButtonControl v-on:click="update" v-bind:disabled="disabled">
                    {{ lang('lbl_0000050') }}
                </ButtonControl>
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import TextboxControl from './../../components/controls/TextControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        TextboxControl,
    },
    data() {
        return {
            new_password: '',
            con_password: '',
            disabled: true,
            passed: false,
        }
    },
    mounted() {
        this.$emit('checkauth', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000049'),
            show: true,
            back: true,
        });

        let state = this.$store.state.forgot;

        if (state.hash == null || state.pin == null) {
            this.$router.push('/forgot-password');
        }
    },
    methods: {
        strong(passed) {
            this.passed = passed;
        },
        typing(obj) {
            this[obj.name] = obj.value;
            this.disabled = this.new_password.length == 0 || this.con_password.length == 0 || !this.passed;
        },
        update() {
            let state = this.$store.state.forgot,
                that = this;

            that.$emit('showloader');
            User.forgotPasswordUpdate(state.uuid, {
                hash: state.hash,
                pin: state.pin,
                otp_token: state.otp.token,
                otp_pin: state.otp.pin,
                password: this.new_password,
                con_password: this.con_password,
            }).then(function() {
                that.$emit('hideloader');
                that.$router.push('/forgot-password/success');
            }).catch(function(error) {
                that.new_password = '';
                that.con_password = '';
                that.$emit('error', error);
            });
        },
    },
}
</script>