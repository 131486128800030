<template>
    <div>
        <ContentWrapperControl>
            <div class="bg-white p-4 rounded-b shadow overflow-hidden mb-4">
                <div class="mb-4 font-bold text-md">
                    <span v-text="lang('lbl_0000040')"></span> <span v-text="getName()"></span>!
                </div>
                <div v-text="lang('lbl_0000070')" class="text-sm leading-normal text-grey-darkest"></div>
            </div>
            <div class="bg-white p-4 rounded shadow">
                <div class="mb-3">
                    <TextControl @typing="typing" 
                        v-bind:value="value"
                        v-bind:placeholder="lang('lbl_0000042')" 
                        name="password" 
                        type="password" />
                </div>
                <div>
                    <ButtonControl v-on:click="proceed" v-bind:disabled="disabled" theme="error">
                            {{ lang('lbl_0000015') }}
                    </ButtonControl>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import TextControl from './../../components/controls/TextControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        TextControl,
    },
    data() {
        return {
            value: '',
            disabled: true,
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000069'),
            show: true,
            back: true,
        });
    },
    methods: {
        typing(obj) {
            this.value = obj.value;
            this.disabled = this.value.length == 0;
        },
        proceed() {
            let that = this,
                state = this.$store.state;

            this.$emit('showloader');
            User.deactivateRequest(state.uuid, this.value).then(function(response) {
                let data = response.data.data;
                state.deactivate.hash = data.hash;
                state.deactivate.otp.pin = data.pin;
                that.$emit('hideloader');
                that.$router.push('/settings/deactivate/verify');
            }).catch(function(error) {
                that.value = '';
                that.$emit('error', error);
            });
        },
        getName() {
            let name = (this.$store.state.info.first_name || '').toLowerCase();

            return name.replace(/\b\w/g, char => char.toUpperCase());
        },
    },
}
</script>