<template>
    <div class="flex">
        <CountdownItemControl v-bind:value="days" v-bind:title="lang('lbl_0000114')" />
        <CountdownItemControl v-bind:value="hour" v-bind:title="lang('lbl_0000115')" />
        <CountdownItemControl v-bind:value="min" v-bind:title="lang('lbl_0000116')" />
        <CountdownItemControl v-bind:value="sec" v-bind:title="lang('lbl_0000117')" last />
    </div>
</template>

<script>
import CountdownItemControl from './CountdownItemControl.vue';
import mixin from './../../mixin.js';

export default {
    name: 'v-countdown',
    mixins: [
        mixin,
    ],
    components: {
        CountdownItemControl,
    },
    data() {
        return {
            days: '00',
            hour: '00',
            min: '00',
            sec: '00',
        }
    },
    mounted() {
        let that = this,
            state = that.$store.state;

        function tick() {
            let loop = setTimeout(function() {
                let now = new Date().getTime(),
                    date,
                    reg_start = new Date(state.ga.registration.start_date),
                    vot_start = new Date(state.ga.voting.start_date),
                    vot_end = new Date(state.ga.voting.end_date),
                    message;
                
                // Registration is still not open.
                if(reg_start.getTime() > now) {
                    message = that.lang('lbl_0000118');
                    date = new Date(state.ga.registration.start_date);
                }

                // Voting is still not open.
                else if(reg_start.getTime() < now && vot_start.getTime() > now) {
                    state.ga.registration.started = true;

                    if (!state.ga.can_vote) {
                        message = that.lang('lbl_0000161');
                        date = new Date(state.ga.registration.end_date);
                    }
                    else {
                        if (!state.ga.registered) {
                            message = that.lang('lbl_0000161');
                            date = new Date(state.ga.registration.end_date);
                        }
                        else {
                            message = that.lang('lbl_0000119');
                            date = new Date(state.ga.voting.start_date);
                        }
                    }
                }

                // Voting is still not done.
                else if(vot_start.getTime() < now && vot_end.getTime() > now) {
                    state.ga.voting.started = true;

                    if (!state.ga.can_vote) {
                        message = that.lang('lbl_0000161');
                        date = new Date(state.ga.registration.end_date);
                    }
                    else {
                        if (!state.ga.registered) {
                            message = that.lang('lbl_0000161');
                            date = new Date(state.ga.registration.end_date);
                        }
                        else {
                            message = that.lang('lbl_0000120');
                            date = new Date(state.ga.voting.end_date);
                        }
                    }
                }
                else {
                    state.ga.voting.ended = true;
                }
                
                let distance = (date - now),
                    days = Math.floor(distance / (1000 * 60 * 60 * 24)),
                    hour = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                    min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
                    sec = Math.floor((distance % (1000 * 60)) / 1000);

                    that.days = (days < 10 ? '0' + days : days).toString();
                    that.hour = (hour < 10 ? '0' + hour : hour).toString();
                    that.min = (min < 10 ? '0' + min : min).toString();
                    that.sec = (sec < 10 ? '0' + sec : sec).toString();
                    
                    that.$emit('ticking', message);
                    
                    if (now > vot_end) {
                        that.$emit('ended');
                        clearTimeout(loop);
                    }
                    else {
                        clearTimeout(loop);
                        tick();
                    }

            }, 1000);
        }

        tick();
    },
}
</script>
