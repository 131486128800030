<template>
    <ContentWrapperControl class="mt-4">
        <div class="bg-white p-4 mb-3 rounded shadow">
            <div class="border-t">
                <SwitchSettingControl @error="error" 
                    @toggle="toggle"
                    @success="success"
                    v-show="false"
                    v-bind:title="lang('lbl_0000108')"
                    v-bind:description="lang('lbl_0000109')"
                    :type="1" />
                <SwitchSettingControl @error="error"
                    @toggle="toggle"
                    @success="success" 
                    v-bind:title="lang('lbl_0000110')"
                    v-bind:description="lang('lbl_0000111')"
                    :type="2" />
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import SwitchSettingControl from './../../components/controls/SwitchSettingControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
        SwitchSettingControl,
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000107'),
            show: true,
            back: true,
        });
    },
    methods: {
        error(error) {
            this.$emit('error', error);
        },
        toggle() {
            this.$emit('showblinder');
        },
        success() {
            this.$emit('hideblinder');
        },
    },
}
</script>