<template>
    <ContentWrapperControl>
        <div class="bg-white p-6 rounded shadow my-4">
            <div v-text="lang('lbl_0000038')" class="text-sm text-grey-darkest mb-4"></div>
            <div>
                <ButtonControl v-on:click="login">
                    {{ lang('lbl_0000039') }}
                </ButtonControl>
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import ButtonControl from './../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../components/controls/ContentWrapperControl.vue';
import User from './../services/User.js';
import mixin from './../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
    },
    data() {
        return {}
    },
    mounted() {
        this.$emit('checkauth', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000037'),
            show: true,
            back: false,
        });

        let state = this.$store.state.registration;

        if (!state.done || state.session == null || state.uuid == null || state.hash == null || state.pin == null) {
            this.$router.push('/signup');
        }
    },
    methods: {
        login() {
            let that = this,
                state = this.$store.state.registration;

            this.$emit('showloader');
            User.createSession({
                type: 5,
                hash: state.session,
                reg_token: state.hash,
                reg_pin: state.pin,
            }).then(function(response) {
                let data = response.data.data;

                that.setUserData(data);
                that.makeCookie(data.hash, true);
                that.resetStore();
                that.$emit('login');
                that.$emit('hideloader');
                that.$router.push('/dashboard');
            }).catch(function(error) {
                that.$emit('error', error);
            });
        },
        resetStore() {
            let state = this.$store.state.registration;

            state.last_name = '';
            state.first_name = '';
            state.account_no = '';
            state.serial_no = '';
            state.username = '';
            state.email = '';
            state.mobile_no = '';
            state.password = '';
            state.con_password = '';
            state.agree = false;
            state.hash = null;
            state.pin = null;
            state.mpin = null;
            state.done = false;
            state.session = null;
            state.uuid = null;
            state.otp.mobile.pin = null;
            state.otp.mobile.token = null;
            state.otp.email.pin = null;
            state.otp.email.token = null;
        },
    },
}
</script>
