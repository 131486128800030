<template>
    <div>
        <ModalControl @closed="closed" 
            v-bind:show="display" 
            v-bind:title="lang('lbl_0000106')" 
            theme="success"
            dismiss>
                <div class="w-full text-center">
                    <div class="w-full">
                        <img v-bind:src="getCode()" class="block" />
                    </div>
                </div>
        </ModalControl>
        <div class="bg-primary w-full">
            <div class="flex pb-6 px-3">
                <div class="flex-grow pt-6">
                    <div class="bg-white rounded mx-auto mt-6 shadow-md papsi-avatar overflow-hidden">
                        <img v-if="loaded" v-on:click="openQr" v-bind:src="getCode()" class="block" />
                        <img v-if="!loaded" v-bind:src="getLoader()" class="block" />
                    </div>
                    <div v-text="name" class="text-center text-white text-2xl font-bold pt-2"></div>
                </div>
            </div>
            <div class="max-w-limit mx-auto px-4">
                <div class="bg-white py-2 rounded-t">
                    <div class="px-3 flex pb-3">
                        <div class="flex-1 text-sm border-r">
                            <div v-text="lang('lbl_0000093')" class="font-bold truncate"></div>
                            <div v-text="account_no" class="text-primary text-sm pt-1"></div>
                        </div>
                        <div class="flex-1 text-right text-sm">
                            <div v-text="lang('lbl_0000094')" class="font-bold truncate"></div>
                            <div v-text="getSerial()" class="text-primary text-sm pt-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ContentWrapperControl>
            <div class="bg-white p-4 pt-0 mb-3 border-b rounded-b shadow border-grey">
                <div class="border-t">
                    <InfoBarControl v-bind:value="type" v-bind:title="lang('lbl_0000102')" icon="papsi_memtype.svg" />
                </div>
            </div>
            <InfoGroupControl v-if="gender != null || date_of_birth != null" v-bind:title="lang('lbl_0000097')">
                <InfoBarControl v-if="gender != null" v-bind:value="gender" v-bind:icon="getGenderIcon()" v-bind:title="lang('lbl_0000104')" />
                <InfoBarControl v-if="date_of_birth != null" v-bind:value="age" icon="papsi_age.svg" v-bind:title="lang('lbl_0000103')" />
                <InfoBarControl v-if="date_of_birth != null" v-bind:value="date_of_birth" icon="papsi_date_of_birth.svg" v-bind:title="lang('lbl_0000105')" />
            </InfoGroupControl>
            <InfoGroupControl v-show="!$store.state.express" v-bind:title="lang('lbl_0000079')">
                <InfoBarControl v-bind:value="mobile" v-bind:title="lang('lbl_0000021')" icon="papsi_setting_mobile.svg" />
                <InfoBarControl v-bind:value="email" v-bind:title="lang('lbl_0000022')" icon="papsi_setting_email.svg" />
            </InfoGroupControl>
            <InfoGroupControl v-if="tin != null" v-bind:title="lang('lbl_0000100')">
                <InfoBarControl v-bind:value="tin" v-bind:title="lang('lbl_0000098')" icon="papsi_tax.svg" />
            </InfoGroupControl>
            <InfoGroupControl v-bind:title="lang('lbl_0000101')">
                <InfoBarControl v-bind:value="username" v-bind:title="lang('lbl_0000041')" icon="papsi_setting_username.svg" />
                <InfoBarControl v-bind:value="date" v-bind:title="lang('lbl_0000099')" icon="papsi_date.svg" />
            </InfoGroupControl>
        </ContentWrapperControl>
    </div>
</template>

<script>
import datetime from 'date-and-time';
import parseMobile from 'libphonenumber-js';
import ContentWrapperControl from '../components/controls/ContentWrapperControl.vue';
import InfoBarControl from '../components/controls/InfoBarControl.vue';
import InfoGroupControl from '../components/controls/InfoGroupControl.vue';
import ModalControl from '../components/controls/ModalControl.vue';
import mixin from './../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
        InfoBarControl,
        InfoGroupControl,
        ModalControl,
    },
    data() {
        return {
            username: '',
            account_no: '',
            serial_no: '',
            name: '',
            gender: '',
            date_of_birth: '',
            age: '',
            tin: '',
            type: '',
            email: '',
            mobile: '',
            date: '',
            display: false,
            loaded: false,
            size: 400,
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);
        this.$emit('shownavbar', {
            type: 'primary',
        });

        let state = this.$store.state;

        if(state.login) {
            this.username = state.info.username;
            this.email = state.info.email;
            this.name = state.info.first_name + ' ' + state.info.last_name;
            this.account_no = state.info.account_no;
            this.serial_no = state.info.serial_no;
            this.date_of_birth = state.info.date_of_birth != null ? this.formatDate(state.info.date_of_birth) : null;
            this.age = state.info.age != null ? state.info.age.toString() : null;
            this.gender = state.info.gender;
            this.tin = state.info.tin;
            this.date = state.info.registered != null ? this.formatDate(state.info.registered) : null;
            this.type = state.info.regular != null ? (state.info.regular ? this.lang('lbl_0000095') : this.lang('lbl_0000096')) : null;
            
            if(state.info.mobile_no != null)
            {
                let mobile = parseMobile(state.info.mobile_no, 'PH').formatNational().split(' ');

                this.mobile = '(' + mobile[0] + ') ' + mobile[1] + ' ' + mobile[2];
            }

            this.loadQr();
        }
    },
    methods: {
        getGenderIcon() {
            return this.gender.toLowerCase() == 'male' ? 'papsi_gender_male.svg' : 'papsi_gender_female.svg';
        },
        loadQr() {
            let url = this.getCode(),
                that = this,
                img = new Image();
                img.src = url;
                img.onload = function() {
                    let t = setTimeout(function() {
                        that.loaded = true;
                        clearTimeout(t);
                    }, 1000);
                };
        },
        getSerial() {
            return this.serial_no != null ? this.serial_no : '??????';
        },
        getCode() {
            return "https://quickchart.io/qr?text=" + this.$store.state.qr + "&size=" + this.size;
        },
        getLoader() {
            return process.env.VUE_APP_MEDIA_URL + "images/loader/gear-spinner.gif";
        },
        openQr() {
            this.display = true;
        },
        closed() {
            this.display = false;
        },
        formatDate(date) {
            return datetime.format(new Date(date), 'MM/DD/YYYY');
        },
    }
}
</script>

<style scoped>
.papsi-avatar {
    width: 140px;
    height: 140px;
}
</style>