<template>
    <div>
        <ContentWrapperControl class="mt-4">
            <div class="bg-white p-4 rounded shadow">
                <div class="mb-3">
                    <TextControl @typing="typing" 
                        v-bind:value="tin" 
                        name="tin" 
                        type="text" 
                        :maxlength="11" />
                </div>
                <div>
                    <ButtonControl v-on:click="update" v-bind:disabled="disabled">
                        {{ lang('lbl_0000050') }}
                    </ButtonControl>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import TextControl from './../../components/controls/TextControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        TextControl,
    },
    data() {
        return {
            theme: 'error',
            display: false,
            message: '',
            changed: false,
            disabled: true,
            tin: '',
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000066'),
            show: true,
            back: true,
        });

        this.tin = this.$store.state.info.tin || '';
    },
    methods: {
        typing(n) {
            this.tin = n.value;

            if(this.tin.length == 3 || this.tin.length == 7) {
                this.tin += '-';
            }

            this.disabled = this.tin.length != 11 || (this.tin == this.$store.state.info.tin);
        },
        update() {
            let that = this;

            this.$emit('showloader');
            User.updateTin(this.$store.state.uuid, this.tin).then(function() {
                that.$store.state.info.tin = that.tin;
                that.disabled = true;
                that.changed = false;
                that.$emit('hideloader');
                that.$emit('success', that.lang('lbl_0000068'));
            }).catch(function(error) {
                that.$emit('error', error);
            });
        },
        closed() {
            this.display = false;
        },
    },
    watch: {
        tin(val) {
            this.changed = val != this.$store.state.info.tin;
        }
    },
}
</script>