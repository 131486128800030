<template>
    <div>
        <PromptControl @ok="ok"
            @closed="closed"
            @cancel="cancel"
            v-bind:show="prompt.show" 
            v-bind:title="prompt.title" 
            v-bind:message="prompt.message" />
        <ContentWrapperControl>
            <div class="bg-white p-4 rounded-b shadow overflow-hidden mb-4">
                <div v-text="lang('lbl_0000053')" class="text-sm leading-normal text-grey-darkest"></div>
            </div>
            <div class="bg-white p-4 rounded shadow overflow-hidden">
                <div>
                    <div class="mb-4">
                        <TextControl @typing="typing" 
                            v-bind:value="value"  
                            type="text" 
                            name="username" />
                    </div>
                    <div>
                        <ButtonControl v-on:click="update" v-bind:disabled="disabled">
                            {{ lang('update') }}
                        </ButtonControl>
                    </div>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from 'vue-router';
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import PromptControl from './../../components/controls/PromptControl.vue';
import TextControl from './../../components/controls/TextControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        PromptControl,
        TextControl,
    },
    data() {
        return {
            value: '',
            disabled: true,
            changed: false,
            href: null,
            prompt: {
                show: false,
                title: null,
                message: null,
            },
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000054'),
            show: true,
            back: true,
        });

        let that = this;

        onBeforeRouteLeave((to) => {
            that.href = to.fullPath;

            if(that.changed) {
                that.prompt.title = that.lang('lbl_0000056');
                that.prompt.message = that.lang('lbl_0000057');
                that.prompt.show = true;

                return false;
            }
        });

        this.value = this.$store.state.info.username;
    },
    methods: {
        ok() {
            this.changed = false;
            this.$router.push(this.href);
        },
        cancel() {
            this.prompt.show = false;
        },
        closed() {
            this.prompt.show = false;
        },
        typing(obj) {
            this.value = obj.value;

            this.disabled = this.value == 0 || (this.value == this.$store.state.info.username);
        },
        update() {
            let that = this,
                state = this.$store.state;

            this.$emit('showloader');
            User.updateUsername(state.uuid, this.value).then(function() {
                that.$store.state.info.username = that.value;
                that.changed = false;
                that.disabled = true;
                that.$emit('hideloader');
                that.$emit('success', that.lang('lbl_0000059'));
            }).catch(function(error) {
                that.$emit('error', error);
            });
        },
    },
    watch: {
        value(val) {
            this.changed = (val != this.$store.state.info.username);
        },
    }
}
</script>