<template>
    <div class="bg-grey-light p-1 rounded mb-1 overflow-hidden flex">
        <div v-bind:style="[ getSource() ]" class="w-menu bg-cover bg-no-repeat bg-center rounded-full shadow"></div>
        <div v-text="name" class="p-2 text-xs leading-normal truncate text-grey-darkest"></div>
    </div>
</template>

<script>
export default {
    name: 'v-vote-card-item',
    props: {
        id: {
            type: String,
        },
        candidate: {
            type: String,
        },
    },
    data() {
        return {
            name: null,
            photo: null,
        }
    },
    mounted() {
        let data = this.$store.state.candidates[this.id],
            that = this;

        data.forEach(function(item) {
            if(item.code == that.candidate) {
                that.name = item.name;
                that.photo = item.photo;
            }
        });
    },
    methods: {
        getSource() {
            return "background-image: url('" + this.photo + "')";
        },
    }
}
</script>