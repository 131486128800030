<template>
    <div class="py-3 border-b flex">
        <div class="flex-grow leading-normal pr-2">
            <div v-text="title" class="text-sm font-bold"></div>
            <div v-text="description" class="text-xs italic text-grey-dark"></div>
        </div>
        <div>
            <SwitchControl @toggle="toggle" v-bind:checked="checked" v-bind:disabled="disabled" />
        </div>
    </div>
</template>

<script>
import SwitchControl from './SwitchControl.vue';
import User from './../../services/User.js';

export default {
    name: 'v-switch-setting',
    components: {
        SwitchControl,
    },
    props: {
        title: {
            type: String,
        },
        description: {
            type: String,
        },
        type: {
            type: Number,
        },
    },
    data() {
        return {
            disabled: false,
            checked: false,
        }
    },
    mounted() {
        this.checked = this.isChecked();
    },
    methods: {
        toggle(value) {
            let that = this,
                state = this.$store.state;

            this.disabled = true;
            this.checked = value;

            User.setConfig(state.uuid, this.type, this.checked ? 1 : 0).then(function() {
                let config = state.config,
                    index = -1,
                    has = false;

                config.forEach(function(item, i) {
                    if(that.type == item.type) {
                        has = true;
                        index = i;
                    }
                });

                if(has) {
                    state.config[index].status = that.checked;
                }
                else {
                    state.config.push({
                        type: that.type,
                        status: that.checked,
                    });
                }

                that.disabled = false;
                that.$emit('success');
            }).catch(function(error) {
                that.disabled = false;
                that.$emit('error', error);
            });

            this.$emit('toggle', value);
        },
        isChecked() {
            let that = this,
                config = this.$store.state.config,
                has = false;
        
            config.forEach(function(item) {
                if(item.status && that.type == item.type) {
                    has = true;
                }
            });

            return has;            
        },
    }
}
</script>