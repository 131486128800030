<template>
    <div>
        <PromptControl @ok="ok"
            @cancel="cancel"
            @closed="closed"
            v-bind:show="prompt"
            v-bind:title="lang('lbl_0000113')"
            v-bind:message="lang('lbl_0000122')" />
        <ModalControl @closed="modalClosed"
            v-bind:show="modal"
            v-bind:title="lang('lbl_0000155')" 
            theme="success"
            dismiss>
            <div>
                <div class="mb-4">
                    <div v-text="lang('lbl_0000156')" class="font-bold text-sm leading-normal"></div>
                    <div class="pt-2">
                        <div class="flex leading-normal">
                            <div v-text="formatDate($store.state.ga.registration.start_date)" class="flex-1 p-1 rounded text-sm truncate text-white bg-theme-elecom"></div>
                            <div v-text="lang('lbl_0000157')" class="px-2 text-sm flex items-center justify-center text-grey-darkest"></div>
                            <div v-text="formatDate($store.state.ga.registration.end_date)" class="flex-1 p-1 rounded text-sm truncate text-white bg-theme-elecom"></div>
                        </div>
                    </div>
                </div>
                <div class="mb-4 border-t">
                    <div v-text="lang('lbl_0000163')" class="font-bold text-sm leading-normal pt-2"></div>
                    <div class="pt-2">
                        <div class="flex leading-normal">
                            <div v-text="formatDate($store.state.ga.voting.start_date)" class="flex-1 p-1 rounded text-sm truncate text-white bg-theme-elecom"></div>
                            <div v-text="lang('lbl_0000157')" class="px-2 text-sm flex items-center justify-center text-grey-darkest"></div>
                            <div v-text="formatDate($store.state.ga.voting.end_date)" class="flex-1 p-1 rounded text-sm truncate text-white bg-theme-elecom"></div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalControl>
        <ContentWrapperControl class="mt-4">
            <div class="rounded-t shadow overflow-hidden">
                <img v-bind:src="image" class="w-full border-0 m-0 p-0 block" />
            </div>
            <div class="bg-white border-b border-grey shadow rounded-b overflow-hidden">
                <div v-show="loading">
                    <img v-bind:src="getLoader()" class="block mx-auto" />
                </div>
                <div v-if="!loading && !voting_ended" class="flex px-4 pt-2">
                    <div class="font-bold text-base leading-normal flex-grow flex items-center uppercase">
                        <span v-text="message"></span>
                    </div>
                    <div class="w-icon">
                        <img v-on:click="modalOpen" v-bind:src="require('@/assets/icons/papsi_calendar.svg')" />
                    </div>
                </div>
                <div v-if="!loading && voting_ended" v-text="lang('lbl_0000112')" class="py-4 text-center text-grey-dark text-sm leading-normal"></div>
                <div v-if="!loading && !voting_ended">
                    <div>
                        <div class="px-4 py-2">
                            <CountdownControl @ticking="ticking" 
                                @ended="electionEnds" />
                        </div>
                    </div>
                    <div v-show="isButtonVisible( )" class="px-4 pb-4">
                        <ButtonControl v-on:click="openPrompt">
                            {{ getButtonLabel() }}
                        </ButtonControl>
                    </div>
                    <div v-show="!voting_ended && registration_started && !voting_started && registered" v-text="lang('lbl_0000162')" class="px-4 pb-4 text-center text-sm border-t pt-4 text-grey-dark"></div>
                    <div v-show="!voting_ended && voting_started && voted" v-text="lang('lbl_0000164')" class="px-4 pb-4 text-center text-sm border-t pt-4 text-grey-dark"></div>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import datetime from 'date-and-time';
import ButtonControl from './../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../components/controls/ContentWrapperControl.vue';
import CountdownControl from './../components/controls/CountdownControl.vue';
import ModalControl from './../components/controls/ModalControl.vue';
import PromptControl from './../components/controls/PromptControl.vue';
import GA from '../services/GA.js';
import mixin from './../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        CountdownControl,
        ModalControl,
        PromptControl,
    },
    data() {
        return {
            image: '',
            loading: true,
            registration_started: false,
            voting_started: false,
            voting_ended: false,
            title: null,
            message: '',
            voted: false,
            registered: false,
            can_vote: false,
            prompt: false,
            modal: false,
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);
        this.$emit('shownavbar', {
            type: 'primary',
        });

        this.image = this.getMediaPath('/ad/ga25_banner_sm.jpg');

        let state = this.$store.state;

        if (!state.deactivated && state.has_pin) {
            this.loadInfo();
        }
    },
    methods: {
        isButtonVisible() {
            return !this.voting_ended && ((this.voting_started && !this.voted && this.can_vote) || (this.registration_started && !this.registered));
        },
        loadInfo() {
            let that = this;

            GA.info().then(function(response) {
                let data = response.data.data,
                    state = that.$store.state.ga;

                state.code = data.code;
                state.title = data.title;
                state.mev = data.user.mev;
                state.signed = data.user.signed;
                state.can_vote = data.user.can_vote;
                state.proxy_holder = data.user.proxy_holder;
                state.total_votes = data.user.total_votes;
                state.voted = data.user.voted;
                state.registered = data.user.registered;
                state.registration.start_date = data.schedules.registration_start;
                state.registration.started = data.schedules.registration_started;
                state.registration.end_date = data.schedules.registration_end;
                state.registration.ended = data.schedules.registration_ended;
                state.voting.start_date = data.schedules.voting_start;
                state.voting.started = data.schedules.voting_started;
                state.voting.end_date = data.schedules.voting_end;
                state.voting.ended = data.schedules.voting_ended;

                that.registration_started = state.registration.started;
                that.voting_started = state.voting.started;
                that.voting_ended = state.voting.ended;
                that.registered = state.registered;
                that.voted = state.voted;
                that.can_vote = state.can_vote;
                that.loading = false;
            }).catch(function(error) {
                that.loading = false;
                that.$emit('error', error);
            });
        },
        ticking(message) {
            let state = this.$store.state;

            this.registration_started = state.ga.registration.started;
            this.voting_started = state.ga.voting.started;
            this.voting_ended = state.ga.voting.ended;
            this.message = message;
        },
        electionEnds() {
            this.voting_ended = true;
        },
        getLoader() {
            return process.env.VUE_APP_MEDIA_URL + "images/loader/gear-spinner.gif";
        },
        getButtonLabel() {
            let state = this.$store.state;

            if (this.voting_started && !state.ga.voted && state.ga.registered) {
                return this.lang('lbl_0000121');
            }
            else {
                return this.lang('lbl_0000113');
            }
        },
        openPrompt() {
            let state = this.$store.state;

            if (this.voting_started && !state.ga.voted && state.ga.registered) {
                this.$router.push('/ga/election/auth');
            }
            else {
                this.prompt = true;
            }
        },
        ok() {
            let state = this.$store.state;

            if (this.voting_started && !state.ga.voted && state.ga.registered) {
                this.vote();
            }
            else {
                this.register();
            }
        },
        cancel() {
            this.prompt = false;
        },
        closed() {
            this.prompt = false;
        },
        register() {
            let that = this,
                state = this.$store.state;

            this.$emit('showloader');
            GA.register(state.ga.code).then(function(response) {
                state.ga.registered = true;
                that.registered = true;
                that.$emit('success', response.data.data.message);
            }).catch(function(error) {
                that.$emit('error', error);
            });
        },
        vote() {
            this.$router.push('/ga/vote');
        },
        modalOpen() {
            this.modal = true;
        },
        modalClosed() {
            this.modal = false;
        },
        formatDate(date) {
            return datetime.format(new Date(date), 'MM/DD/YYYY hh:mm A');
        },
    },
}
</script>

<style scoped>
.papsi-status-dim {
    width: 10px;
    height: 10px;
}
</style>