<template>
    <div>
        <input @keydown="handleKeyDown"
            @focus="focused"
            v-model="text"
            v-bind:id="getId()"
            v-bind:type="type"
            autocomplete="off"
            spellcheck="false"
            ref="input"
            maxlength="1"
            class="w-full text-center text-2xl font-bold bg-transparent py-2 outline-none papsi-digit-input" />
    </div>
</template>

<script>
import mixin from './../../mixin.js';

export default {
    name: 'digit-input-control',
    mixins: [
        mixin,
    ],
    props: {
        num: {
            type: Number,
        },
        length: {
            type: Number,
        },
        focus: {
            type: Boolean,
            default: false,
        },
        empty: {
            type: Boolean,
            default: false,
        },
        digit: {
            type: Number,
        },
        hidden: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            text: '',
            type: 'text',
        }
    },
    mounted() {
        this.type = this.hidden ? 'password' : 'text';
        this.text = this.value || '';
    },
    methods: {
        getId() {
            return "digit_" + this.num;
        },
        setFocus() {
            this.$refs.input.focus();
        },
        focused() {
            this.$emit('focused', this.num);
        },
        handleKeyDown(e) {
            let length = this.text.toString().length;

            if (e.key == 'Backspace' || e.key == 'Delete') {
                if (this.num > 1) {
                    if (length == 0) {
                        this.$emit('prev', {
                            number: this.num - 1,
                        });
                    }
                }

                return;
            }
            else {
                if (length == 0 && (e.key >= '0' && e.key <= '9')) {
                    if (this.num != this.length) {
                        this.text = e.key;
                        this.$emit('next', {
                            number: this.num + 1,
                        });
                    }
                    else {
                        this.text = e.key;
                    }
                }
            }

            if (length == 0) {
                e.preventDefault();
            }
        },
    },
    watch: {
        focus(val) {
            if (val) {
                this.setFocus();
            }
        },
        empty(val) {
            if (val) {
                this.val = '';
            }            
        },
        hidden(val) {
            this.type = val ? 'password' : 'text';
        },
        text(val) {
            this.$emit('typing', {
                number: this.num,
                value: val,
            });
        },
        value(val) {
            this.text = val || '';
        },
    }
}
</script>

<style scoped>
.papsi-digit-input {
    font-family: 'Courier New', Courier, monospace;
}
</style>
