<template>
    <ContentWrapperControl class="mt-4">
        <div class="bg-white p-4 rounded shadow">
            <div v-text="lang('lbl_0000051')" class="pb-4 text-sm text-grey-darkest"></div>
            <div>
                <ButtonControl v-on:click="login">
                    {{ lang('lbl_0000043') }}
                </ButtonControl>
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
    },
    mounted() {
        this.$emit('checkauth', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000052'),
            show: true,
            back: false,
        });

        let state = this.$store.state.forgot;

        if (state.hash == null || state.pin == null) {
            this.$router.push('/forgot-password');
        }

        this.resetStore();
    },
    methods: {
        login() {
            this.$router.push('/');
        },
        resetStore() {
            let state = this.$store.state.forgot;

            state.value = '';
            state.uuid = null;
            state.hash = null;
            state.pin = null;
            state.first_name = null;
            state.last_name = null;
            state.email = null;
            state.mobile = null;
            state.otp.token = null;
            state.otp.pin = null;
        },
    }
}
</script>