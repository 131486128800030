<template>
    <ContentWrapperControl class="mt-4">
        <div v-show="!loaded" class="bg-white rounded shadow p-4">
            <div class="flex justify-content items-center w-full">
                <img v-bind:src="getLoader()" class="block mx-auto" />
            </div>
        </div>
        <div v-show="loaded && branches.length > 0">
            <div class="mb-4">
                <div v-text="lang('lbl_0000127')" class="mb-3 font-bold text-lg"></div>
                <div>
                    <BranchItemControl v-for="branch in luzon"
                        v-bind:key="branch.abbr"
                        v-bind:title="branch.name"
                        v-bind:address="branch.address"
                        v-bind:contact1="branch.contact1 || null"
                        v-bind:contact2="branch.contact2 || null" />
                </div>
            </div>
            <div class="mb-4">
                <div v-text="lang('lbl_0000128')" class="mb-3 font-bold text-lg"></div>
                <div>
                    <BranchItemControl v-for="branch in visayas"
                        v-bind:key="branch.abbr"
                        v-bind:title="branch.name"
                        v-bind:address="branch.address"
                        v-bind:contact1="branch.contact1 || null"
                        v-bind:contact2="branch.contact2 || null" />
                </div>
            </div>
            <div class="mb-4">
                <div v-text="lang('lbl_0000129')" class="mb-3 font-bold text-lg"></div>
                <div>
                    <BranchItemControl v-for="branch in mindanao"
                        v-bind:key="branch.abbr"
                        v-bind:title="branch.name"
                        v-bind:address="branch.address"
                        v-bind:contact1="branch.contact1 || null"
                        v-bind:contact2="branch.contact2 || null" />
                </div>
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import BranchItemControl from './../../components/controls/BranchItemControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import Branch from './../../services/Branch.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        BranchItemControl,
        ContentWrapperControl,
    },
    data() {
        return {
            loaded: false,
            branches: [],
            luzon: [],
            visayas: [],
            mindanao: [],
        }
    },
    mounted() {
        let title = this.lang('lbl_0000126'),
            that = this;

        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });

        if(this.$store.state.branches.length > 0) {
            this.loaded = true;
            this.$store.state.branches.forEach(function(item) {
                that.branches.push(item);
            });

            this.distribute();
        }
        else {
            this.loadBranches();
        }
    },
    methods: {
        getLoader() {
            return process.env.VUE_APP_MEDIA_URL + "images/loader/gear-spinner.gif";
        },
        loadBranches() {
            let that = this;

            Branch.all().then(function(response) {
                that.loaded = true;

                let data = response.data.data;

                data.forEach(function(item) {
                    that.$store.state.branches.push(item);
                    that.branches.push(item);
                });

                that.distribute();
            }).catch(function(error) {
                that.loaded = true;
                that.$error('error', error);
            });
        },
        distribute() {
            let that = this;

            this.branches.forEach(function(item) {
                let group = parseInt(item.island_group);

                if(group == 1) {
                    that.luzon.push(item);
                }
                else if(group == 2) {
                    that.visayas.push(item);
                }
                else if(group == 3) {
                    that.mindanao.push(item);
                }
            });
        },
    }
}
</script>