<template>
    <div>
        <PromptControl @ok="ok"
            @cancel="cancel"
            @closed="closed"
            v-bind:show="prompt"
            v-bind:title="lang('lbl_0000140')"
            v-bind:message="lang('lbl_0000124')" />
        <ContentWrapperControl>
            <div class="bg-white p-4 rounded shadow mt-4">
                <div v-text="lang('lbl_0000137')" class="text-sm leading-normal mb-4 text-grey-darkest"></div>
                <div>
                    <ButtonControl v-on:click="openPrompt" theme="error">
                        {{ lang('lbl_0000139') }}
                    </ButtonControl>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import PromptControl from './../../components/controls/PromptControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        PromptControl,
    },
    data() {
        return {
            prompt: false,
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', true);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000138'),
            show: true,
            back: true,
        });

        let state = this.$store.state;

        if (state.deactivate.hash == null) {
            this.$store.state.push('/settings/deactivate');
        }
    },
    methods: {
        openPrompt() {
            this.prompt = true;
        },
        ok() {
            let that = this,
                state = this.$store.state;

            this.$emit('showloader');
            User.deactivateAccount(state.uuid, {
                otp_pin: state.deactivate.otp.pin,
                otp_token: state.deactivate.otp.token,
                deactivation_key: state.deactivate.hash,
            }).then(function() {
                state.deactivate.hash = null;
                state.deactivate.otp.pin = null;
                state.deactivate.otp.token = null;
                that.logout();
            }).catch(function(error) {
                that.$emit('error', error);
            });
        },
        logout() {
            let that = this;

            User.logout().then(function() {
                that.destroyCookie();
                that.resetUserData();
                that.$emit('logout');
                that.$emit('hideloader');
                that.$router.push('/');
            }).catch(function(error) {
                that.$emit('error', error);
            });
        },
        cancel() {
            this.prompt = false;
        },
        closed() {
            this.prompt = false;
        },
    },
}
</script>