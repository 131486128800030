<template>
    <ContentWrapperControl>
        <div class="bg-white mb-4 rounded-b shadow">
            <div class="p-4 text-sm">
                <div class="mb-4 text-lg font-bold border-b pb-4"><span v-text="lang('lbl_0000040')"></span>, <span v-text="upperCaseFirst($store.state.registration.first_name)"></span>!</div>
                <div v-text="lang('lbl_0000020')" class="leading-tight text-grey-darkest"></div>
            </div>
        </div>
        <div class="bg-white overflow-hidden mt-4 rounded shadow mb-4 leading-normal text-sm">
            <div class="pb-2">
                <div v-on:click="goToVerifyMobile" class="flex px-4 py-4 border-b">
                    <div class="flex items-center justify-center pr-2">
                        <img v-bind:src="require('@/assets/icons/papsi_setting_mobile.svg')" class="w-menu" />
                    </div>
                    <div class="flex-grow font-bold text-md truncate flex items-center">
                        <div v-text="lang('lbl_0000021')"></div>
                    </div>
                    <div v-show="!isMobileVerified()" v-text="mobile_no" class="flex items-center text-right text-green"></div>
                    <div v-show="isMobileVerified()" class="flex items-center justify-center">
                        <img v-bind:src="require('@/assets/icons/papsi_check.svg')" class="w-menu" />
                    </div>
                </div>
                <div v-on:click="goToVerifyEmail" class="flex px-4 py-4 border-b">
                    <div class="flex items-center justify-center pr-2">
                        <img v-bind:src="require('@/assets/icons/papsi_setting_email.svg')" class="w-menu" />
                    </div>
                    <div class="flex-grow font-bold text-md truncate flex items-center">
                        <div v-text="lang('lbl_0000022')"></div>
                    </div>
                    <div v-show="!isEmailVerified()" v-text="email" class="flex items-center text-right text-green"></div>
                    <div v-show="isEmailVerified()" class="flex items-center justify-center">
                        <img v-bind:src="require('@/assets/icons/papsi_check.svg')" class="w-menu" />
                    </div>
                </div>
                <div class="pt-2 px-2">
                    <ButtonControl v-on:click="proceed" v-bind:disabled="!(isMobileVerified() && isEmailVerified())">
                        {{ lang('lbl_0000033') }}
                    </ButtonControl>
                </div>
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import ButtonControl from './../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../components/controls/ContentWrapperControl.vue';
import User from './../services/User.js';
import mixin from './../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
    },
    data() {
        return {
            disabled: true,
            mobile_no: null,
            email: null,
        }
    },
    mounted() {
        this.$emit('checkauth', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000013'),
            show: true,
            back: true,
        });

        let state = this.$store.state,
            mobile = state.registration.mobile_no,
            email = state.registration.email;

        this.mobile_no = '+63' + mobile;
        this.email = email;

        if (state.registration.hash == null || state.registration.pin == null)
        {
            this.$router.push('/signup');
        }
    },
    methods: {
        upperCaseFirst(name) {
            name = name.toLowerCase();

            return name.charAt(0).toUpperCase() + name.slice(1);
        },
        goToVerifyMobile() {
            if (!this.isMobileVerified()) {
                this.$router.push('/signup/verify/mobile');
            }
        },
        goToVerifyEmail() {
            if (!this.isEmailVerified()) {
                this.$router.push('/signup/verify/email');
            }
        },
        isMobileVerified() {
            return this.$store.state.registration.otp.mobile.token != null;
        },
        isEmailVerified() {
            return this.$store.state.registration.otp.email.token != null;
        },
        proceed() {
            let that = this,
                state = this.$store.state.registration;

            this.$emit('showloader');
            User.createRequest({
                pin: state.pin,
                hash: state.hash,
                first_name: state.first_name,
                last_name: state.last_name,
                account_no: state.account_no.toUpperCase(),
                serial_no: state.serial_no.toUpperCase(),
                username: state.username,
                email: state.email,
                mobile_no: '+63' + state.mobile_no,
                password: state.password,
                con_password: state.con_password,
                agreement: state.agree,
                mobile_otp_pin: state.otp.mobile.pin,
                mobile_otp_token: state.otp.mobile.token,
                email_otp_pin: state.otp.email.pin,
                email_otp_token: state.otp.email.token,
            }).then(function() {
                that.$emit('hideloader');
                that.$router.push('/signup/pin');
            }).catch(function(error) {
                that.$emit('error', error);
            });
        },
    }
}
</script>
