<template>
    <div>
        <ContentWrapperControl>
            <div class="bg-white p-4 rounded-b shadow border-b mb-4">
                <div class="mb-2 text-md font-bold">
                    <span v-text="lang('lbl_0000040')"></span> <span v-text="getName()"></span>!
                </div>
                <div v-text="lang('lbl_0000031')" class="text-sm leading-normal text-grey-darkest"></div>
            </div>
            <div class="bg-white rounded shadow border-b">
                <div>
                    <PinControl @typing="typing"
                            @complete="complete" 
                            v-bind:label="lang(title)"
                            v-bind:value="pin" />
                </div>
                <div class="px-4 pb-4 flex">
                    <div class="flex-1 mr-2">
                        <ButtonControl v-on:click="next" v-bind:disabled="disabled">
                            {{ lang(btn_label) }}
                        </ButtonControl>
                    </div>
                    <div class="flex-1">
                        <ButtonControl v-on:click="logout" theme="error">
                            {{ lang('lbl_0000123') }}
                        </ButtonControl>
                    </div>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import PinControl from './../../components/controls/PinControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        PinControl,
    },
    data() {
        return {
            confirm: false,
            disabled: true,
            pin: null,
            first_pin: null,
            title: '',
            btn_label: '',
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', true);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000030'),
            show: true,
            back: false,
        });

        if (this.$store.state.has_pin) {
            this.$router.push('/dashboard');
        }

        this.title = this.lang('lbl_0000032');
        this.btn_label = this.lang('lbl_0000033');
    },
    methods: {
        typing(value) {
            this.disabled = !(value.length == 6);
        },
        complete(pin) {
            this.pin = pin;
        },
        getName() {
            let name = (this.$store.state.info.first_name || '').toLowerCase();

            return name.replace(/\b\w/g, char => char.toUpperCase());
        },
        next() {
            let that = this;

            if (this.confirm) {
                if (this.pin == this.first_pin) {
                    this.save();
                }
                else {
                    this.pin = '';
                    this.$emit('alert', {
                        theme: 'error',
                        title: this.lang('lbl_0000034'),
                        message: this.lang('lbl_0000036'),
                    });
                }
            }
            else {
                this.$emit('showloader');
                User.testPin(this.pin).then(function() {
                    that.confirm = true;
                    that.title = that.lang('lbl_0000035');
                    that.btn_label = that.lang('lbl_0000153');
                    that.first_pin = that.pin;
                    that.pin = '';
                    that.$emit('hideloader');
                }).catch(function(error) {
                    that.pin = '';
                    that.$emit('error', error);
                });
            }
        },
        save() {
            let that = this,
                state = this.$store.state;

            this.$emit('showloader');
            User.enrollPin(state.uuid, this.pin).then(function() {
                state.has_pin = true;
                that.$emit('success', that.lang('lbl_0000154'));
                that.$router.push('/dashboard');
            }).catch(function(error) {
                that.$emit('error', error);
            });
        },
        logout() {
            let that = this;

            that.$emit('showloader');
            User.logout().then(function() {
                that.destroyCookie();
                that.resetUserData();
                that.$emit('logout');
                that.$emit('hideloader');
                that.$router.push('/');
            }).catch(function(error) {
                that.$emit('error', error);
            });
        },
    }
}
</script>