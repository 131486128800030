<template>
    <div>
        <div class="w-full h-screen flex flex-col">
            <div class="h-screen w-full flex flex-col">
                <div class="bg-primary py-6 w-full text-center">
                    <div class="pt-6">
                        <img class="papsi-main-logo" v-bind:src="image" />
                    </div>
                    <div v-text="lang('lbl_0000141')" class="text-white font-bold text-xl mt-6 uppercase"></div>
                    <div v-text="lang('lbl_0000142')" class="text-white font-thin text-md italic mt-1"></div>
                </div>
                <div class="w-full pb-6">
                    <ContentWrapperControl class="mt-6">
                        <div class="mb-4">
                            <TextControl @typing="typing"
                                v-bind:value="username"
                                v-bind:placeholder="lang('lbl_0000041')"
                                type="text"
                                name="username" />
                        </div>
                        <div class="mb-4">
                            <TextControl @typing="typing"
                                v-bind:value="password"
                                v-bind:placeholder="lang('lbl_0000042')"
                                type="password"
                                name="password" />
                        </div>
                        <div class="flex mb-4 text-md">
                            <div class="flex-1">
                                <router-link to="/signup" class="text-primary no-underline">
                                    {{ lang('lbl_0000143') }}
                                </router-link>
                            </div>
                            <div class="flex-1 text-right">
                                <router-link to="/help" class="text-primary no-underline">
                                    {{ lang('lbl_0000144') }}
                                </router-link>
                            </div>
                        </div>
                        <div>
                            <ButtonControl v-on:click="login" v-bind:disabled="disabled">
                                {{ lang('lbl_0000043') }}
                            </ButtonControl>
                        </div>
                    </ContentWrapperControl>
                </div>
                <div class="px-4 text-center leading-normal text-grey-darkest text-xs w-full papsi-curr-version">
                    <span v-text="version"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonControl from './../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../components/controls/ContentWrapperControl.vue';
import TextControl from './../components/controls/TextControl.vue';
import mixin from './../mixin.js';
import User from './../services/User.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        TextControl,
    },
    data() {
        return {
            disabled: true,
            username: '',
            password: '',
            image: '',
            version: '',
        }
    },
    mounted() {
        this.$emit('hidenavbar');
        this.version = this.lang('lbl_0000044') + ' ' + process.env.VUE_APP_VERSION;
        this.image = this.getMediaPath('/logo/papsi-mobile.jpg');

        if(this.$store.state.login) {
            this.$router.push('/dashboard');
        }
    },
    methods: {
        typing(obj) {
            this[obj.name] = obj.value;
            this.enableButton();
        },
        enableButton() {
            this.disabled = this.username.length == 0 || this.password.length == 0;
        },
        login() {
            let that = this;

            this.$emit('showloader');
            User.authenticate(this.username, this.password).then(function(response) {
                that.createSession(response.data.data);
            }).catch(function(error) {
                that.password = '';
                that.$emit('error', error);
            });
        },
        createSession(data) {
            let that = this;

            User.createSession({
                type: 1,
                hash: data.hash,
                username: this.username,
                password: this.password,
            }).then(function(response) {
                let info = response.data.data;

                that.setUserData(info);
                that.makeCookie(info.hash, true);
                that.$emit('login');
                that.$emit('hideloader');
                that.$router.push('/dashboard');
            }).catch(function(error) {
                that.password = '';
                that.$emit('error', error);
            });
        },
        showLoader() {
            this.$emit('showloader');
        },
        hideLoader() {
            this.$emit('hideloader');
        },
    },
}
</script>

<style scoped>
.papsi-main-logo {
    width: 100%;
    max-width: 200px;
}
.papsi-curr-version {
    bottom: 0px;
}
</style>