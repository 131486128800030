<template>
    <ContentWrapperControl>
        <div class="bg-white mb-4 rounded-b shadow">
            <div class="p-4 text-sm">
                <div v-text="lang('lbl_0000031')" class="leading-tight text-grey-darkest"></div>
            </div>
        </div>
        <div class="bg-white overflow-hidden mt-4 rounded shadow mb-4 leading-normal text-sm">
            <div>
                <PinControl @typing="typing"
                    @complete="complete"
                    v-bind:value="pin"
                    v-bind:label="lang('lbl_0000032')" />
            </div>
            <div class="px-4 pb-4">
                <ButtonControl v-on:click="next" v-bind:disabled="disabled">
                    {{ lang('lbl_0000033') }}
                </ButtonControl>
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import ButtonControl from './../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../components/controls/ContentWrapperControl.vue';
import PinControl from './../components/controls/PinControl.vue';
import User from './../services/User.js';
import mixin from './../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        PinControl,
    },
    data() {
        return {
            disabled: true,
            pin: '',
        }
    },
    mounted() {
        this.$emit('checkauth', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000035'),
            show: true,
            back: true,
        });

        let state = this.$store.state.registration;

        if (state.mpin == null) {
            this.$router.push('/signup');
        }
    },
    methods: {
        complete(pin) {
            this.pin = pin;
        },
        typing(pin) {
            this.pin = pin;
        },
        next() {
            let state = this.$store.state.registration,
                that = this,
                pin = state.mpin;

            if (pin === this.pin) {
                this.$emit('showloader');
                User.create({
                    hash: state.hash,
                    pin: state.pin,
                    mpin: state.mpin,
                    auto_login: true,
                }).then(function(response) {
                    that.$store.state.registration.done = true;
                    that.$store.state.registration.uuid = response.data.data.uuid;
                    that.$store.state.registration.session = response.data.data.hash;
                    that.$emit('hideloader');
                    that.$router.push('/signup/done');
                }).catch(function(error) {
                    that.pin = '';
                    that.$emit('error', error);
                });
            }
            else {
                this.$emit('alert', {
                    theme: 'error',
                    title: this.lang('lbl_0000058'),
                    message: this.lang('lbl_0000036'),
                });
            }
        },
    },
    watch: {
        pin(value) {
            this.disabled = !(value.length == 6);
        },
    },
}
</script>