<template>
    <ContentWrapperControl class="mt-4">
        <div class="bg-white rounded shadow overflow-hidden">
            <img v-bind:src="image" class="block w-full" />
        </div>
    </ContentWrapperControl>
</template>

<script>
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
    },
    data() {
        return {
            image: '',
        }
    },
    mounted() {
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000132'),
            show: true,
            back: true,
        });

        this.image = this.getMediaPath('/misc/contact-us-sm.jpg');
    },
}
</script>
