<template>
    <div>
        <PromptControl @ok="promptOk"
            @closed="promptClosed"
            @cancel="promptCancel"
            v-bind:show="prompt.display"
            v-bind:title="prompt.title"
            v-bind:message="prompt.message" />
        <ContentWrapperControl>
            <div class="p-3 flex rounded-b shadow mb-4 bg-white">
                <div v-text="lang('lbl_0000003')" class="leading-normal text-sm text-grey-darkest"></div>
            </div>
            <div class="bg-white p-4 rounded shadow">
                <div class="mb-4">
                    <div class="border flex flex-col text-sm">
                        <div class="flex border-b">
                            <div v-text="lang('lbl_0000041')" class="border-r papsi-table-field-w p-2 font-bold bg-grey-lightest"></div>
                            <div class="flex-grow flex items-center p-2 truncate text-grey-darker">
                                <span v-text="username"></span>
                            </div>
                        </div>
                        <div v-show="email.length != 0" v-bind:class="{ 'border-b': mobile.length != 0 }" class="flex">
                            <div v-text="lang('lbl_0000005')" class="border-r papsi-table-field-w p-2 font-bold bg-grey-lightest"></div>
                            <div class="flex-grow flex items-center p-2 truncate text-grey-darker">
                                <span v-text="email"></span>
                            </div>
                        </div>
                        <div v-show="mobile.length != 0" class="flex">
                            <div v-text="lang('lbl_0000007')" class="border-r papsi-table-field-w p-2 font-bold bg-grey-lightest"></div>
                            <div v-text="mobile" class="flex-grow p-2 truncate text-grey-darker"></div>
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <div class="flex-1 mr-2">
                        <ButtonControl v-on:click="proceed">
                            {{ lang('lbl_0000004') }}
                        </ButtonControl>
                    </div>
                    <div class="flex-1">
                        <ButtonControl v-on:click="back" theme="error">
                            {{ lang('lbl_0000006') }}
                        </ButtonControl>
                    </div>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import PromptControl from './../../components/controls/PromptControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        PromptControl,
    },
    data() {
        return {
            uuid: null,
            hash: null,
            username: '',
            name: '',
            email: '',
            mobile: '',
            pin: null,
            value: null,
            prompt: {
                display: false,
                title: '',
                message: '',
            },
        }
    },
    mounted() {
        this.$emit('checkauth', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000001'),
            show: true,
            back: false,
        });

        let state = this.$store.state;

        this.uuid = state.forgot.uuid;
        this.hash = state.forgot.hash;
        this.pin = state.forgot.pin;
        this.value = state.forgot.value;
        this.username = state.forgot.username;
        this.email = this.sensorEmail(state.forgot.email);
        this.mobile = this.sensorMobile(state.forgot.mobile);
        
        if(this.email.length == 0 && this.mobile.length == 0 && this.hash.length == 0 && this.uuid.length == 0 && this.pin.length == 0) {
            this.$router.push('/forgot-password');
        }
    },
    methods: {
        proceed() {
            this.prompt.title = this.lang('lbl_0000009');
            this.prompt.message = this.lang('lbl_0000010');
            this.prompt.display = true;
        },
        sensorEmail(email) {
            email = email || '';

            if (email.length != 0) {
                let username = email.split('@')[0],
                    host = email.split('@')[1],
                    length = username.length,
                    maskLength = Math.floor(length * 0.5),
                    start = Math.floor((length - maskLength) / 2),
                    end = start + maskLength;

                let before = username.slice(0, start),
                    after = username.slice(end),
                    mask = '*'.repeat(maskLength),
                    value = before + mask + after;

                email = value + '@' + host;
            }

            return email;
        },
        sensorMobile(mobile) {
            mobile = mobile || '';
            
            if (mobile.length != 0) {
                let chars = mobile.split('');

                chars[5] = "*";
                chars[6] = "*";
                chars[7] = "*";
                chars[8] = "*";
                chars[9] = "*";

                mobile = chars.join('');
            }

            return mobile;
        },
        makeRequest() {
            let that = this;

            that.$emit('showloader');
            User.forgotPasswordRequest(that.uuid, that.hash, that.pin, that.value).then(function(response) {
                that.$store.state.forgot.otp.pin = response.data.data.pin;
                that.prompt.display = false;
                that.$router.push('/forgot-password/verify/otp');
                that.$emit('hideloader');
            }).catch(function(error) {
                that.$emit('error', error);
            });
        },
        reset() {
            let state = this.$store.state;

            state.forgot.value = null;
            state.forgot.uuid = null;
            state.forgot.hash = null;
            state.forgot.pin = null;
            state.forgot.email = null;
            state.forgot.mobile = null;
        },
        back() {
            this.reset();
            this.$router.back();
        },
        promptOk() {
            this.makeRequest();
        },
        promptClosed() {
            this.prompt.display = false;
        },
        promptCancel() {
            this.otp.display = false;
        },
        showLoader() {
            this.$emit('showloader');
        },
        hideLoader() {
            this.$emit('hideloader');
        },
    }
}
</script>

<style scoped>
.papsi-table-field-w {
    width: 120px !important;
    max-width: 120px !important;
}
</style>