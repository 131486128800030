<template>
    <div>
        <PromptControl @ok="ok"
            @closed="closed"
            v-bind:show="show" 
            v-bind:title="lang('lbl_0000192')"
            v-bind:message="lang('lbl_0000193')" />
        <div class="w-full h-screen flex flex-col">
            <div class="bg-primary">
                <div class="pt-3 px-3 flex">
                    <div class="h-menu w-menu">
                        <img v-on:click="back" v-show="false" src="@/assets/icons/papsi_back.svg" />
                    </div>
                    <div class="flex-grow text-center pt-6 pb-3">
                        <div v-text="lang('lbl_0000189')" class="text-xl text-white font-bold uppercase pt-6"></div>
                        <div v-text="lang('lbl_0000188')" class="text-white opacity-75 text-sm mt-2 italic"></div>
                    </div>
                    <div class="h-menu w-menu"></div>
                </div>
                <div class="max-w-limit w-full mx-auto">
                    <div class="px-4">
                        <div class="bg-white py-2 w-full rounded-t"></div>
                    </div>
                </div>
            </div>
            <div class="max-w-limit w-full mx-auto">
                <div class="px-4">
                    <div class="bg-white py-2 w-full rounded-b border-b"></div>
                </div>
            </div>
            <div class="flex-grow w-full overflow-auto py-4">
                <ContentWrapper>
                    <div>
                        <VoteCardControl v-for="item in categories" 
                            v-bind:id="item.id"
                            v-bind:key="item.id"
                            v-bind:title="lang(item.title)"
                            v-bind:type="item.type != null ? lang(item.type) : null"
                            v-bind:href="item.href"
                            v-bind:color="item.theme" />
                    </div>
                    <div ref="footerspacer"></div>
                </ContentWrapper>
            </div>
        </div>
        <div ref="footerbar" class="p-3 bg-white border-t fixed w-full z-footer papsi-summary-footer">
            <ButtonControl v-on:click="vote" theme="error">
                {{ lang('lbl_0000190') }}
            </ButtonControl>
        </div>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapper from './../../components/controls/ContentWrapperControl.vue';
import GA from '../../services/GA.js';
import PromptControl from './../../components/controls/PromptControl.vue';
import VoteCardControl from './../../components/controls/VoteCardControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapper,
        PromptControl,
        VoteCardControl,
    },
    data() {
        return {
            show: false,
            categories: [
                {
                    id: 'bod',
                    title: 'lbl_0000169',
                    type: 'lbl_0000183',
                    href: '/ga/election/vote/bod',
                    theme: 'bg-theme-bod',
                },
                {
                    id: 'audit',
                    title: 'lbl_0000176',
                    type: null,
                    href: '/ga/election/vote/audit',
                    theme: 'bg-theme-adcom',
                },
                {
                    id: 'elecom_of',
                    title: 'lbl_0000173',
                    type: 'lbl_0000174',
                    href: '/ga/election/vote/elecom/officer',
                    theme: 'bg-theme-elecom',
                },
                {
                    id: 'elecom_ep',
                    title: 'lbl_0000173',
                    type: 'lbl_0000175',
                    href: '/ga/election/vote/elecom/enlisted-personnel',
                    theme: 'bg-theme-elecom',
                },
            ],
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', true);

        let state = this.$store.state.ga;

        this.$store.state.vote.done = true;
        if(!state.registered || state.voted) {
            this.$router.push('/dashboard');
        }

        this.setSpacer();
    },
    methods: {
        setSpacer() {
            this.$refs.footerspacer.style.height = (parseInt(this.$refs.footerbar.offsetHeight) + 40) + "px";
        },
        back() {
            this.$router.back();
        },
        closed() {
            this.show = false;
        },
        vote() {
            this.show = true;
        },
        ok() {
            this.$emit('showloader');

            let state = this.$store.state.selected,
                that = this,
                data = [];

            state.bod.forEach(function(item) {
                data.push(item);
            });

            state.elecom_of.forEach(function(item) {
                data.push(item);
            });

            state.elecom_ep.forEach(function(item) {
                data.push(item);
            });

            state.audit.forEach(function(item) {
                data.push(item);
            });

            let store = this.$store.state;

            GA.vote(store.ga.code, store.vote.voter_key, store.vote.ballot, JSON.stringify(data)).then(function(response) {
                that.$store.state.ga.voted = true;
                that.$store.state.vote.end_date = response.data.timestamp;
                that.$router.push('/ga/election/ballot');
                that.$emit('hideloader');
            })
            .catch(function(error) {
                that.$emit('error', error);
            });
        }
    }
}
</script>

<style scoped>
.papsi-summary-footer {
    bottom: 0;
    left: 0;
}
</style>