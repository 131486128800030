<template>
    <div>
        <ContentWrapperControl>
            <div class="bg-white p-4 rounded-b shadow border-b mb-4">
                <div v-show="!verify" class="mb-2 text-md font-bold">
                    <span v-text="lang('lbl_0000040')"></span> <span v-text="getName()"></span>!
                </div>
                <div v-text="lang(message)" class="text-sm leading-normal text-grey-darkest"></div>
            </div>
            <div class="bg-white p-4 rounded shadow border-b">
                <div v-show="!verify">
                    <div v-show="mobile_no != null" class="rounded border-b p-2 flex mb-2 bg-grey-lighter">
                        <div class="flex items-center justify-center pr-2 border-r">
                            <img v-bind:src="require('@/assets/icons/papsi_setting_mobile.svg')" class="w-icon" />
                        </div>
                        <div class="flex-grow flex items-center text-sm pl-2 text-grey-darkest">
                            <div v-text="mobile_no"></div>
                        </div>
                    </div>
                    <div v-show="email != null" class="rounded border-b p-2 flex mb-4 bg-grey-lighter">
                        <div class="flex items-center justify-center pr-2 border-r">
                            <img v-bind:src="require('@/assets/icons/papsi_setting_email.svg')" class="w-icon" />
                        </div>
                        <div class="flex-grow flex items-center text-sm pl-2 text-grey-darkest">
                            <div v-text="email"></div>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="flex-1 mr-2">
                            <ButtonControl v-bind:disabled="(email == null && mobile_no == null)" v-on:click="activate">
                                {{ lang('lbl_0000151') }}
                            </ButtonControl>
                        </div>
                        <div class="flex-1">
                            <ButtonControl v-on:click="logout" theme="error">
                                {{ lang('lbl_0000123') }}
                            </ButtonControl>
                        </div>
                    </div>
                </div>
                <div v-show="verify">
                    <OTPControl @success="success"
                        @failed="failed"
                        @showloader="showloader"
                        @hideloader="hideloader" 
                        v-bind:type="otp.type" 
                        v-bind:pin="otp.pin"
                        v-bind:mobile="mobile_no"
                        v-bind:email="email"
                        v-bind:name="name" />
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import OTPControl from './../../components/controls/OTPControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        OTPControl,
    },
    data() {
        return {
            name: '',
            mobile_no: null,
            email: null,
            verify: false,
            hash: null,
            message: 'lbl_0000150',
            saving: false,
            otp: {
                type: 4,
                pin: null,
                token: null,
            },
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', true);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000149'),
            show: true,
            back: false,
        });

        let state = this.$store.state;

        if (!state.deactivated) {
            this.$router.push('/dashboard');
        }

        this.mobile_no = state.info.mobile_no;
        this.email = state.info.email;
        this.name = state.info.first_name;
    },
    methods: {
        success(data) {
            let that = this,
                state = this.$store.state;

            this.saving = true;
            this.otp.token = data.otp_token;
            User.activateAccount(state.uuid, {
                reactivation_key: this.hash,
                otp_token: this.otp.token,
                otp_pin: this.otp.pin,
            }).then(function() {
                state.deactivate_date = null;
                state.deactivated = false;
                that.saving = false;
                that.$emit('hideloader');
                that.$router.push('/dashboard');
            }).catch(function(error) {
                that.saving = false;
                that.$emit('error', error);
            });
        },
        failed(message) {
            this.$emit('alert', {
                theme: 'error',
                title: this.lang('lbl_0000034'),
                message: message,
            });
        },
        getName() {
            let name = (this.$store.state.info.first_name || '').toLowerCase();

            return name.replace(/\b\w/g, char => char.toUpperCase());
        },
        activate() {
            let that = this,
                state = this.$store.state;

            this.$emit('showloader');
            User.activateRequest(state.uuid).then(function(response) {
                let data = response.data.data;

                that.message = 'lbl_0000152';
                that.hash = data.hash;
                that.otp.type = data.type;
                that.otp.pin = data.pin;
                that.verify = true;
                that.$emit('hideloader');
            }).catch(function(error) {
                that.verify = false;
                this.$emit('error', error);
            });
        },
        showloader() {
            this.$emit('showloader');
        },
        hideloader() {
            if (!this.saving) {
                this.$emit('hideloader');
            }
        },
        logout() {
            let that = this;

            that.$emit('showloader');
            User.logout().then(function() {
                that.destroyCookie();
                that.resetUserData();
                that.$emit('logout');
                that.$emit('hideloader');
                that.$router.push('/');
            }).catch(function(error) {
                that.$emit('error', error);
            });
        },
    }
}
</script>