<template>
    <ContentWrapperControl>
        <SettingCardControl v-bind:title="lang('lbl_0000078')" class="mt-4">
            <SettingCardItemControl v-bind:value="username" 
                v-bind:title="lang('lbl_0000041')"
                icon="papsi_setting_username.svg"
                href="/settings/username" />
            <SettingCardItemControl v-bind:value="password" 
                v-bind:title="lang('lbl_0000042')"
                icon="papsi_setting_password.svg"
                href="/settings/password" />
            <SettingCardItemControl v-if="false" v-bind:title="lang('lbl_0000081')" 
                icon="papsi_memdata.svg"
                href="/settings/info" />
        </SettingCardControl>
        <SettingCardControl v-bind:title="lang('lbl_0000079')">
            <SettingCardItemControl v-bind:value="mobile"
                v-bind:title="lang('lbl_0000021')"
                icon="papsi_setting_mobile.svg"
                href="/settings/mobile" />
            <SettingCardItemControl v-bind:value="email"
                v-bind:title="lang('lbl_0000022')"
                icon="papsi_setting_email.svg"
                href="/settings/email" />
        </SettingCardControl>
        <SettingCardControl v-bind:title="lang('lbl_0000080')">
            <SettingCardItemControl v-bind:title="lang('lbl_0000032')"
                icon="papsi_pin.svg"
                href="/settings/security/pin" />
            <SettingCardItemControl v-bind:title="lang('lbl_0000082')"
                icon="papsi_setting_auth.svg"
                href="/settings/security/authentication" />
            <SettingCardItemControl v-bind:title="lang('lbl_0000069')"
                icon="papsi_setting_deactivate.svg"
                href="/settings/deactivate" />
        </SettingCardControl>
    </ContentWrapperControl>
</template>

<script>
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import SettingCardControl from './../../components/controls/SettingCardControl.vue';
import SettingCardItemControl from './../../components/controls/SettingCardItemControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
        SettingCardControl,
        SettingCardItemControl,
    },
    data() {
        return {
            username: '',
            password: '',
            mobile: '',
            email: '',
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000085'),
            show: true,
            back: true,
        });

        let state = this.$store.state;

        this.username = state.info.username;
        this.password = state.password;
        this.mobile = state.info.mobile_no;
        this.email = state.info.email;
    },
}
</script>