<template>
    <li v-on:click="toggle" v-bind:class="{ 'mr-3': (index % 2 == 0) }" class="block float-left mb-3 papsi-thumbnail-w">
        <div class="bg-white rounded border-b shadow overflow-hidden w-full">
            <div v-bind:style="[ getSource() ]" v-bind:class="[ getSize() ]" class="bg-grey-light bg-center bg-no-repeat papsi-thumbnail-box">
                <div v-show="status" class="w-menu h-menu rounded-full">
                    <img v-bind:src="getIcon()" class="mt-1 ml-1" />
                </div>
            </div>
            <div v-bind:class="{
                'bg-theme-bod' : status && color == 'bg-theme-bod',
                'bg-theme-elecom' : status && color == 'bg-theme-elecom',
                'bg-theme-adcom' : status && color == 'bg-theme-adcom',
                'text-white' : status,
            }" class="text-center py-1 uppercase papsi-thumbnail-text-h">
                <div v-text="name" class="px-4 break-words text-xs font-bold"></div>
            </div>
        </div>
    </li>
</template>

<script>
export default {
    name: 'v-vote-item',
    props: {
        id: {
            type: String,
        },
        group: {
            type: String,
        },
        index: {
            type: Number,
        },
        name: {
            type: String,
        },
        category: {
            type: String,
        },
        selected: {
            type: Boolean,
        },
        count: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
        },
        freeze: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: 'bg-theme-bod',
        },
        available: {
            type: Boolean,
        },
        photo: {
            type: String,
        }
    },
    data() {
        return {
            status: false,
            theme: 'bg-theme-bod',
            path: null,
            path_g: null,
            path_y: null,
            loaded: false,
        }
    },
    mounted() {
        this.status = this.selected;
        this.path_g = process.env.VUE_APP_MEDIA_URL + "images/candidates/g/" + this.id + ".jpg";
        this.path_y = process.env.VUE_APP_MEDIA_URL + "images/candidates/y/" + this.id + ".jpg";
        this.path = this.status ? this.path_y : this.path_g;

        this.loadImage(this.photo);
    },
    methods: {
        loadImage(src) {
            let that = this,
                img = new Image();
                img.src = src;
                img.onload = function() {
                    let t = setTimeout(function() {
                        that.loaded = true;
                        clearTimeout(t);
                    }, 500);
                };
        },
        toggle() {
            if(!this.freeze || (this.count <= this.max && this.available) || this.status) {
                if(this.count < this.max) {
                    this.status = !this.status;
                    this.$emit('toggled', this.id);
                }
                else
                {
                    if(this.status) {
                        this.status = false;
                        this.$emit('toggled', this.id);
                    }
                    else
                    {
                        this.$emit('overselect', this.id);
                    }
                }
            }
            else if(this.count == this.max) {
                this.$emit('overselect', this.id);
            }
        },
        getSize() {
            return this.loaded ? 'bg-cover' : null;
        },
        getSource() {
            return this.loaded ? "background-image: url('" + this.photo + "')" : "background-image: url('" + process.env.VUE_APP_MEDIA_URL + "images/loader/spinner.gif')";
        },
        getIcon() {
            if(this.group == 'elecom') {
                return require("@/assets/icons/selected/papsi_selected_elecom.svg");
            }
            else if(this.group == 'audit') {
                return require("@/assets/icons/selected/papsi_selected_audit.svg");
            }
            else {
                return require("@/assets/icons/selected/papsi_selected_bod.svg");
            }
        },
    },
    watch: {
        selected(status) {
            this.status = status;

            if(this.status)
            {
                this.$emit('selected', this.id);
            }
            else 
            {
                this.$emit('unselected', this.id);
            }
        },
    }
}
</script>

<style scoped>
.papsi-thumbnail-w {
    width: calc(50% - 6px);
}
.papsi-thumbnail-box {
    width: 100%;
    height: 0px;
    padding-bottom: 100%;
}
.papsi-thumbnail-text-h {
    height: 68px;
    line-height: 20px;
}
</style>