<template>
    <div>
        <PromptControl @ok="ok"
            @cancel="cancel"
            @closed="closed"
            v-bind:show="show"
            v-bind:title="lang('lbl_0000056')"
            v-bind:message="lang('lbl_0000057')" />
        <ContentWrapperControl>
            <div v-show="verify" class="bg-white p-4 rounded-b shadow overflow-hidden">
                <div v-text="lang('lbl_0000073')" class="text-sm italic leading-normal text-grey-darkest"></div>
            </div>
            <div class="bg-white p-4 rounded shadow mt-4">
                <div v-show="!verify">
                    <div class="mb-3">
                        <TextControl @typing="typing" 
                            v-bind:value="value"
                            name="mobile"
                            type="mobile" />
                    </div>
                    <div>
                        <ButtonControl v-on:click="update" v-bind:disabled="disabled">
                            {{ lang('lbl_0000050') }}
                        </ButtonControl>
                    </div>
                </div>
                <div v-show="verify">
                    <OTPControl @success="success"
                        @failed="failed"
                        @showloader="showloader"
                        @hideloader="hideloader"
                        v-bind:type="otp.type"
                        v-bind:pin="otp.pin"
                        v-bind:mobile="full" />
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from 'vue-router';
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import mobile from 'libphonenumber-js';
import OTPControl from './../../components/controls/OTPControl.vue';
import PromptControl from './../../components/controls/PromptControl.vue';
import TextControl from './../../components/controls/TextControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        OTPControl,
        PromptControl,
        TextControl,
    },
    data() {
        return {
            value: '',
            disabled: true,
            changed: false,
            full: null,
            name: null,
            href: null,
            show: false,
            verify: false,
            hash: null,
            saving: false,
            otp: {
                type: 7,
                token: null,
                pin: null,
            },
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000072'),
            show: true,
            back: true,
        });

        let that = this,
            state = this.$store.state;

        onBeforeRouteLeave((to) => {
            that.href = to.fullPath;

            if(that.changed) {
                that.show = true;

                return false;
            }
        });

        if (state.info.mobile_no != null) {
            let mobile = state.info.mobile_no;
            
            this.value = mobile.substring(3, mobile.length);
        }
    },
    methods: {
        success(data) {
            let that = this,
                state = this.$store.state;

            this.saving = true;
            this.otp.token = data.otp_token;
            User.updateMobile(state.uuid, {
                value: this.full,
                otp_token: this.otp.token,
                otp_pin: this.otp.pin,
                update_key: this.hash,
            }).then(function() {
                state.info.mobile_no = that.full;
                that.reset();
                that.$emit('success', that.lang('lbl_0000074'));
            }).catch(function(error) {
                that.reset();
                that.$emit('error', error);
            });
        },
        reset() {
            this.saving = false;
            this.verify = false;
            this.changed = false;
            this.disabled = true;
            this.hash = null;
            this.otp.token = null;
            this.otp.pin = null;
        },
        failed(message) {
            this.$emit('alert', {
                theme: 'error',
                title: this.lang('lbl_0000034'),
                message: message,
            });
        },
        showloader() {
            this.$emit('showloader');
        },
        hideloader() {
            if (!this.saving) {
                this.$emit('hideloader');
            }
        },
        ok() {
            this.changed = false;

            if (this.href != null) {
                this.$router.push(this.href);
            }
        },
        cancel() {
            this.show = false;
        },
        closed() {
            this.show = false;
        },
        typing(obj) {
            this.value = obj.value;
            this.full = '+63' + this.value;

            let mob = mobile(this.full, 'PH');
            
            this.disabled = this.value.length != 10 || (this.full == this.$store.state.info.mobile_no) || !mob.isValid();
            this.changed = (this.full != this.$store.state.info.mobile_no);
        },
        update() {
            let that = this,
                state = this.$store.state;

            this.$emit('showloader');
            User.validateMobile(state.uuid, this.full).then(function(response) {
                let data = response.data.data;

                that.hash = data.hash;
                that.otp.type = data.type;
                that.otp.pin = data.pin;
                that.verify = true;
                that.$emit('hideloader');
            }).catch(function(error) {
                that.reset();
                that.$emit('error', error);
            });
        },
    },
    watch: {
        value(value) {
            this.full = '+63' + value;
        },
    },
}
</script>