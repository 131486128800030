import service from './../service.js';

export default {
    authenticate(username, password) {
        return service.post('/user/login/validate', {
            type: 1,
            username: username,
            password: password,
        });
    },

    createSession(payload) {
        return service.post('/user/login', payload);
    },

    fetchSession() {
        return service.post('/user');
    },
    
    logout() {
        return service.delete('/user/logout');
    },

    updateUsername(uuid, username) {
        return service.put('/user/' + uuid + '/username', {
            value: username,
        });
    },

    updatePassword(uuid, old_password, new_password, con_password) {
        return service.put('/user/' + uuid + '/password', {
            old_password: old_password,
            new_password: new_password,
            con_password: con_password,
        });
    },

    validateEmail(uuid, email) {
        return service.post('/user/' + uuid + '/email', {
            value: email,
        });
    },

    updateEmail(uuid, payload) {
        return service.put('/user/' + uuid + '/email', payload);
    },

    validateMobile(uuid, mobile) {
        return service.post('/user/' + uuid + '/mobile', {
            value: mobile,
        });
    },

    updateMobile(uuid, payload) {
        return service.put('/user/' + uuid + '/mobile', payload);
    },

    testPassword(uuid, password) {
        return service.post('/user/' + uuid + '/password', {
            password: password,
        });
    },

    activateRequest(uuid) {
        return service.post('/user/' + uuid + '/settings/activate');
    },

    activateAccount(uuid, payload) {
        return service.put('/user/' + uuid + '/settings/activate', payload);
    },

    deactivateRequest(uuid, password) {
        return service.post('/user/' + uuid + '/settings/deactivate', {
            password: password,
        });
    },

    deactivateAccount(uuid, payload) {
        return service.delete('/user/' + uuid + '/settings/deactivate', {
            data: payload,
        });
    },

    validate(payload) {
        return service.post('/user/register/validate', payload);
    },

    createRequest(payload) {
        return service.post('/user/register', payload);
    },

    create(payload) {
        return service.post('/user/register/enroll', payload);
    },

    forgotPassword(value) {
        return service.post('/user/recover', {
            value: value,
        });
    },

    forgotPasswordRequest(uuid, hash, pin, value) {
        return service.post('/user/recover/' + uuid, {
            hash: hash,
            pin: pin,
            value: value,
        });
    },

    forgotPasswordUpdate(uuid, payload) {
        return service.put('/user/recover/' + uuid, payload);
    },

    setConfig(uuid, type, status) {
        return service.put('/user/' + uuid + '/settings/config', {
            type: type,
            status: status,
        });
    },

    updateTin(uuid, tin) {
        return service.put('/user/' + uuid + '/tin', {
            value: tin,
        });
    },

    verify(uuid) {
        return service.post('/user/' + uuid + '/verify');
    },

    testPin(pin) {
        return service.post('/user/pin', {
            pin: pin,
        });
    },

    validatePin(uuid, pin) {
        return service.post('/user/' + uuid + '/pin', {
            pin: pin,
        });
    },

    enrollPin(uuid, pin) {
        return service.put('/user/' + uuid + '/pin', {
            value: pin,
        });
    },

    updatePin(uuid, old_pin, new_pin) {
        return service.put('/user/' + uuid + '/pin', {
            value: new_pin,
            pin: old_pin,
        });
    },
}