<template>
    <div>
        <ContentWrapperControl>
            <div class="bg-white rounded-b shadow overflow-hidden p-4 mb-4">
                <div v-text="lang('lbl_0000046')" class="text-sm leading-normal text-grey-darkest"></div>
            </div>
            <div class="bg-white rounded shadow overflow-hidden">
                <OTPControl @success="success"
                    @failed="failed"
                    @showloader="showloader"
                    @hideloader="hideloader"
                    v-bind:type="type"
                    v-bind:pin="pin"
                    v-bind:mobile="mobile_no"
                    v-bind:email="email"
                    v-bind:name="name" />
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import OTPControl from './../../components/controls/OTPControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
        OTPControl,
    },
    data() {
        return {
            type: 1,
            pin: null,
            name: null,
            email: null,
            mobile_no: null,
        }
    },
    mounted() {
        this.$emit('checkauth', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000013'),
            show: true,
            back: false,
        });

        let state = this.$store.state.forgot;

        if (state.hash == null || state.pin == null) {
            this.$router.push('/forgot-password');
        }

        this.pin = state.otp.pin;
        this.mobile_no = state.mobile;
        this.email = state.email;
        this.name = state.first_name;
    },
    methods: {
        success(data) {
            this.$store.state.forgot.otp.token = data.otp_token;
            this.$router.push('/forgot-password/update');
        },
        failed(message) {
            this.$emit('alert', {
                theme: 'error',
                title: this.lang('lbl_0000058'),
                message: message,
            });
        },
        showloader() {
            this.$emit('showloader');
        },
        hideloader() {
            this.$emit('hideloader');
        },
    }
}
</script>