<template>
    <ContentWrapperControl class="mt-4">
        <div class="bg-white shadow rounded p-4">
            <div v-text="lang('lbl_0000202')" class="mb-4"></div>
            <div class="pb-4">
                <RatingControl @toggled="toggled" 
                    @submitted="exit" 
                    v-bind:submit="submit" 
                    :type="1" />
            </div>
            <div>
                <ButtonControl v-on:click="goSubmit" 
                    v-bind:disabled="disabled">
                    {{ lang('lbl_0000190') }}
                </ButtonControl>
            </div>
            <div class="pt-4 text-center text-md">
                <span v-text="lang('lbl_0000200')"></span> <span v-text="lang('lbl_0000199')" v-on:click="exit" class="text-primary"></span>
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from '../../components/controls/ContentWrapperControl.vue';
import RatingControl from '../../components/controls/RatingControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        RatingControl,
    },
    data() {
        return {
            disabled: true,
            submit: false,
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', true);
        this.$emit('shownavbar', {
            type: 'normal',
            title: this.lang('lbl_0000201'),
            show: true,
            back: false,
        });

        let state = this.$store.state.ga;

        if(!state.voted) {
            this.$router.push('/dashboard');
        }
    },
    methods: {
        toggled() {
            this.disabled = false;
        },
        goSubmit() {
            this.submit = true;
            this.$emit('showloader');
        },
        exit() {
            this.$emit('hideloader');
            this.$router.push('/dashboard');
        },
    }
}
</script>