<template>
    <div>
        <ContentWrapperControl>
            <div class="bg-white p-4 rounded-b shadow overflow-hidden mb-4">
                <div v-text="lang('lbl_0000071')" class="text-sm leading-normal text-grey-darkest"></div>
            </div>
            <div class="bg-white p-4 rounded shadow">
                <div>
                    <OTPControl @success="success"
                        @failed="failed"
                        @showloader="showloader"
                        @hideloader="hideloader" 
                        v-bind:type="type" 
                        v-bind:pin="pin"
                        v-bind:mobile="mobile"
                        v-bind:email="email"
                        v-bind:name="name" />
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import OTPControl from './../../components/controls/OTPControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
        OTPControl,
    },
    data() {
        return {
            type: 3,
            email: null,
            mobile: null,
            name: null,
            pin: null,
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', true);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000069'),
            show: true,
            back: true,
        });

        let state = this.$store.state;

        if (state.deactivate.hash == null) {
            this.$router.push('/settings/deactivate');
        }

        this.email = state.info.email;
        this.mobile = state.info.mobile_no;
        this.name = state.info.first_name;
        this.pin = state.deactivate.otp.pin;
    },
    methods: {
        success(data) {
            this.$store.state.deactivate.otp.token = data.otp_token;
            this.$router.push('/settings/deactivate/final');
        },
        failed(message) {
            this.$emit('alert', {
                theme: 'error',
                title: this.lang('lbl_0000034'),
                message: message,
            });
        },
        showloader() {
            this.$emit('showloader');
        },
        hideloader() {
            this.$emit('hideloader');
        },
    },
}
</script>