<template>
    <ContentWrapperControl class="mt-4">
        <div class="bg-white p-6 rounded shadow">
            <img v-bind:src="getImage()" class="block w-full" />
        </div>
    </ContentWrapperControl>
</template>

<script>
import ContentWrapperControl from './../components/controls/ContentWrapperControl.vue';
import mixin from './../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
    },
    mounted() {
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000136'),
            show: true,
            back: true,
        });
    },
    methods: {
        getImage() {
            return this.getMediaPath("/page-not-found.jpg");
        },
    }
}
</script>