<template>
    <ContentWrapperControl>
        <div class="bg-white mb-4 rounded-b shadow">
            <div class="p-4 text-sm">
                <div v-text="lang('lbl_0000024')" class="leading-tight text-grey-darkest"></div>
            </div>
        </div>
        <div class="bg-white overflow-hidden mt-4 rounded shadow mb-4 leading-normal">
            <div class="px-4 mt-4">
                <div class="rounded border-b p-2 flex bg-grey-lighter">
                    <div class="flex items-center justify-center pr-2 border-r">
                        <img v-bind:src="require('@/assets/icons/papsi_setting_mobile.svg')" class="w-icon" />
                    </div>
                    <div class="flex-grow flex items-center text-sm pl-2 text-grey-darkest">
                        <div v-text="mobile_no"></div>
                    </div>
                </div>
            </div>
            <div>
                <OTPControl @success="success"
                    @failed="failed"
                    @showloader="showloader"
                    @hideloader="hideloader" 
                    v-bind:type="type" 
                    v-bind:pin="$store.state.registration.otp.mobile.pin"
                    v-bind:mobile="mobile_no" />
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import ContentWrapperControl from './../components/controls/ContentWrapperControl.vue';
import OTPControl from './../components/controls/OTPControl.vue';
import mixin from './../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
        OTPControl,
    },
    data() {
        return {
            type: 2,
            mobile_no: null,
            email: null,
        }
    },
    mounted() {
        this.$emit('checkauth', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000023'),
            show: true,
            back: true,
        });

        let state = this.$store.state,
            mobile = '+63' + state.registration.mobile_no;

        if (state.registration.hash == null || state.registration.pin == null)
        {
            this.$router.push('/signup');
        }

        this.mobile_no = mobile;
        this.email = state.registration.email;
    },
    methods: {
        showloader() {
            this.$emit('showloader');
        },
        hideloader() {
            this.$emit('hideloader');
        },
        success(data) {
            this.$store.state.registration.otp.mobile.token = data.otp_token;
            this.$router.back();
        },
        failed(message) {
            this.$emit('alert', {
                theme: 'error',
                title: this.lang('lbl_0000058'),
                message: message,
            });
        },
    }
}
</script>