import service from '../service.js';

export default {
    info() {
        return service.get('/ga/info');
    },

    register(code) {
        return service.post('/ga/' + code + '/register');
    },

    validateOtp(otp, code) {
        return service.post('ga/' + code + '/election', {
            otp: otp,
        });
    },

    start(code, secret_key) {
        return service.post('/ga/' + code + '/election/vote/start', {
            secret_key: secret_key,
        });
    },

    candidates(code, type, category = null) {
        let url = '/ga/' + code + '/election/candidates/' + type;

        if (category != null) {
            url += '/' + category;
        }

        return service.get(url);
    },

    vote(code, voter_key, ballot_no, votes) {
        return service.post('/ga/' + code + '/election/vote', {
            voter_key: voter_key,
            ballot_no: ballot_no,
            votes: votes,
        });
    },
}