<template>
    <div>
        <ModalControl @closed="closeVotes" 
            v-bind:show="votes" 
            v-bind:title="lang('lbl_0000171')"
            dismiss
            nopadd>
            <div class="p-2 bg-grey-light">
                <div v-show="!hasSelected()" v-text="lang('lbl_0000184')" class="text-sm text-grey-darkest"></div>
                <div v-show="hasSelected()">
                    <VoteCardControl v-if="$store.state.selected.bod.length != 0"
                        v-bind:update="update"
                        v-bind:title="lang('lbl_0000172')"
                        id="bod"
                        color="bg-theme-bod"
                        noedit />
                    <VoteCardControl v-if="$store.state.selected.elecom_of.length != 0"
                        v-bind:update="update"
                        v-bind:title="lang('lbl_0000173')"
                        v-bind:type="lang('lbl_0000174')"
                        id="elecom_of"
                        color="bg-theme-elecom"
                        noedit />
                    <VoteCardControl v-if="$store.state.selected.elecom_ep.length != 0"
                        v-bind:update="update"
                        v-bind:title="lang('lbl_0000173')"
                        v-bind:type="lang('lbl_0000175')"
                        id="elecom_ep"
                        color="bg-theme-elecom"
                        noedit />
                    <VoteCardControl v-if="$store.state.selected.audit.length != 0"
                        v-bind:update="update"
                        v-bind:title="lang('lbl_0000176')"
                        id="audit"
                        color="bg-theme-adcom"
                        noedit />
                </div>
            </div>
        </ModalControl>
        <PromptControl @ok="ok"
            @closed="closeClear"
            v-bind:title="prompt_title"
            v-bind:message="prompt_msg"
            v-bind:show="prompt_show" />
    <div class="w-full h-screen flex flex-col">
        <div v-bind:class="[ color ]">
            <div class="flex pt-3 px-3">
                <div>
                    <div class="h-menu w-menu">
                        <img v-show="backable" v-on:click="back" src="@/assets/icons/papsi_back.svg" />
                    </div>
                </div>
                <div class="flex-grow text-center pt-6 pb-3">
                    <div v-text="title" class="text-xl text-white font-bold uppercase pt-6"></div>
                    <div v-show="subtitle != null" v-text="subtitle" class="pt-2 text-sm text-white opacity-75 truncate"></div>
                </div>
                <div>
                    <div class="h-menu w-menu rounded-full overflow-hidden papsi-bg-trans">
                        <div class="p-1">
                            <img v-on:click="openVotes" src="@/assets/icons/papsi_list.svg" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="max-w-limit w-full mx-auto">
                <div class="px-4">
                    <div class="bg-white py-2 w-full rounded-t"></div>
                </div>
            </div>
        </div>
        <div class="px-4">
            <div class="bg-white py-2 rounded-b border-b"></div>
        </div>
        <div class="flex-grow w-full overflow-auto">
            <ContentWrapper>
                <div v-if="loaded" class="flex pt-4 w-full">
                    <ul class="list-none m-0 p-0 block w-full">
                        <VoteItemControl v-for="(item, index) in data"
                            @toggled="toggled"
                            @overselect="overselect"
                            v-bind:id="item.code"
                            v-bind:group="category"
                            v-bind:index="index"
                            v-bind:name="item.name"
                            v-bind:category="item.type"
                            v-bind:selected="chosen.indexOf(item.code) != -1"
                            v-bind:count="chosen.length"
                            v-bind:max="max"
                            v-bind:key="item.code"
                            v-bind:photo="item.photo"
                            v-bind:color="color" />
                    </ul>
                </div>
            </ContentWrapper>
            <div ref="footerspacer"></div>
        </div>
    </div>
    <div ref="footerbar" class="bg-white p-2 shadow flex w-full fixed z-footer papsi-bottom-footer-z">
        <div class="papsi-navbar-w">
            <ButtonControl v-on:click="clear" 
                v-bind:disabled="!clearable" 
                theme="error">
                    {{ lang('lbl_0000017') }}
            </ButtonControl>
        </div>
        <div class="flex-grow text-center text-md papsi-navbar-h-lh">
            <span v-text="chosen.length"></span> of <span v-text="max"></span>
        </div>
        <div class="papsi-navbar-w">
            <ButtonControl v-on:click="next" 
                v-bind:disabled="disabled">
                    {{ lang(btn_label) }}
            </ButtonControl>
        </div>
    </div>
    </div>
</template>

<script>
import ButtonControl from './../controls/ButtonControl.vue';
import ContentWrapper from './../controls/ContentWrapperControl.vue';
import GA from '../../services/GA.js';
import ModalControl from './../../components/controls/ModalControl.vue';
import PromptControl from './../../components/controls/PromptControl.vue';
import VoteCardControl from '../../components/controls/VoteCardControl.vue';
import VoteItemControl from './../controls/VoteItemControl.vue';
import mixin from './../../mixin.js';

export default {
    name: 'v-vote',
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapper,
        ModalControl,
        PromptControl,
        VoteCardControl,
        VoteItemControl,
    },
    props: {
        category: {
            type: String,
            default: 'bod',
        },
        id: {
            type: String,
            default: 'bod',
        },
        type: {
            type: String,
            default: null,
        },
        backable: {
            type: Boolean,
            default: false,
        },
        max: {
            type: Number,
            default: 1,
        },
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        href: {
            type: String,
        },
        color: {
            type: String,
            default: 'bg-theme-bod',
        },
        notice: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            data: [],
            loaded: false,
            disabled: true,
            clearable: false,
            chosen: [],
            prompt_title: '',
            prompt_show: false,
            prompt_msg: '',
            votes: false,
            update: false,
            show_message: false,
            btn_label: 'lbl_0000033',
        }
    },
    mounted() {
        let that = this,
            candidates = this.$store.state.candidates,
            voted = this.$store.state.selected[this.id],
            data = candidates[this.id];

        if(voted.length != 0) {
            voted.forEach(function(item) {
                that.chosen.push(item);
            });
        }

        if (this.$store.state.vote.secret_key == null) {
            this.$router.push('/dashboard');
        }

        if(this.$store.state.ga.registered) {
            if(data.length == 0) {
                this.$emit('preload');
                this.load();
            }
            else {
                this.loaded = true;
                this.$emit('loaded');
                data.forEach(function(item) {
                    that.data.push(item);
                });
            }

            this.clearable = this.chosen.length > 0;
            this.disabled = this.chosen.length != this.max;
            this.selected = this.chosen.length;
        }

        if(this.$store.state.vote.done)
        {
            this.btn_label = 'lbl_0000181';
        }
        else {
            this.btn_label = 'lbl_0000033';
        }

        if (this.notice != null) {
            this.$emit('alert', {
                theme: 'info',
                title: this.lang('lbl_0000182'),
                message: this.notice,
                show: true,
            });
        }

        this.setSpacer();
    },
    methods: {
        countAllSelected() {
            let state = this.$store.state.selected;

            return state.bod.length + state.elecom_of.length + state.elecom_ep.length + state.audit.length;
        },
        hasSelected() {
            return this.countAllSelected() != 0;
        },
        setSpacer() {
            this.$refs.footerspacer.style.height = (parseInt(this.$refs.footerbar.offsetHeight) + 40) + "px";
        },
        openVotes() {
            this.votes = true;
        },
        closeVotes() {
            this.votes = false;
        },
        back() {
            if(this.id == 'bod') {
                this.$emit('alert', {
                    theme: 'error',
                    title: this.lang('lbl_0000034'),
                    message: this.lang('lbl_0000177'),
                    show: true,
                });
            }
            else {
                if(this.chosen.length == this.max) {
                    this.$router.back();
                }
                else {
                    this.$emit('alert', {
                        theme: 'error',
                        title: this.lang('lbl_0000034'),
                        message: this.lang('lbl_0000178'),
                        show: true,
                    });
                }
            }
        },
        load() {
            let that = this,
                state = this.$store.state.ga;

            GA.candidates(state.code, this.category, this.type).then(function(response) {
                let data = response.data.data;

                data.forEach(function(item) {
                    that.data.push(item);
                });

                that.loaded = true;
                that.$emit('loaded');
                that.$store.state.candidates[that.id] = data;
            }).catch(function(error) {
                this.$emit('error', error);
            });
        },
        toggled(key) {
            if(this.chosen.indexOf(key) == -1) {
                this.chosen.push(key);
            }
            else {
                let index = this.chosen.indexOf(key);
                this.chosen.splice(index, 1);
            }

            this.clearable = this.chosen.length > 0;
            this.disabled = this.chosen.length != this.max;
            this.selected = this.chosen.length;
            this.update = !this.update;

            this.updateStore();
            this.$emit('toggled', key);
        },
        updateStore() {
            let state = this.$store.state.selected[this.id];

            state.splice(0, state.length);

            this.chosen.forEach(function(item) {
                state.push(item);
            });
        },
        overselect() {
            this.$emit('alert', {
                theme: 'error',
                title: this.lang('lbl_0000208'),
                message: this.lang('lbl_0000180'),
                show: true,
            });
        },
        clear() {
            this.prompt_title = this.lang('lbl_0000017');
            this.prompt_msg = this.lang('lbl_0000179');
            this.prompt_show = true;
        },
        closeClear() {
            this.prompt_show = false;
        },
        ok() {
            this.chosen.splice(0, this.chosen.length);
            this.clearable = this.chosen.length > 0;
            this.disabled = this.chosen.length != this.max;
            this.selected = this.chosen.length;
            this.updateStore();
        },
        next() {
            if(this.$store.state.vote.done) {
                this.$router.push('/ga/election/vote/summary');
            }
            else {
                if(this.chosen.length == this.max) {
                    this.$router.push(this.href);
                }
            }
        },
    }
}
</script>

<style scoped>
.papsi-navbar-h-lh {
    line-height: 40px;
}
.papsi-navbar-w {
    width: 100px;
}
.papsi-bg-trans {
    background-color: rgba(255, 255, 255, 0.30);
}
.papsi-bottom-footer-z {
    bottom: 0px;
    left: 0px;
}
.papsi-category-h {
    height: 42px;
    line-height: 16px;
}
</style>