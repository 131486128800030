<template>
    <div>
        <div>
            <NumberInputControl @typing="typing" 
                @complete="complete" 
                v-bind:length="length"
                v-bind:value="value" />
        </div>
        <div class="px-4 pb-4 flex">
            <ButtonControl v-on:click="validate" v-bind:disabled="disabled">
                {{ lang('lbl_0000016') }}
            </ButtonControl>
        </div>
        <div>
            <div v-show="show" class="pt-2 flex justify-center items-center pb-6">
                <div v-on:click="resend" v-text="lang('lbl_0000018')" class="text-sm leading-none underline text-blue"></div>
            </div>
            <div v-show="!show" class="pt-2 flex justify-center items-center pb-6">
                <div class="text-sm text-grey-darker"><span v-text="lang('lbl_0000019')"></span> <span v-text="min"></span>:<span v-text="formatSeconds(sec)"></span></div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import NumberInputControl from './../../components/controls/NumberInputControl.vue';
import OTP from './../../services/OTP.js';
import mixin from './../../mixin.js';

export default {
    name: 'v-otp-control',
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        NumberInputControl,
    },
    props: {
        type: {
            type: Number,
            required: true,
        },
        pin: {
            type: String,
        },
        mobile: {
            type: String,
            default: null,
        },
        email: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            length: 6,
            value: '',
            disabled: true,
            show: false,
            min: 2,
            sec: 0,
            seconds: 1000,
            minutes: 2,
            t: null,
            otp_pin: null,
            otp_token: null,
        }
    },
    mounted() {
        this.min = this.minutes;
        this.startTimer();
    },
    methods: {
        startTimer() {
            let that = this;

            this.t = setInterval(function() {
                if (that.min == 0 && that.sec == 0) {
                    that.show = true;
                    that.reset(that.minutes);
                    clearInterval(that.t);
                }
                else {
                    if (that.sec == 0) {
                        that.min--;
                        that.sec = 59;
                    }
                    else {
                        that.sec--;
                    }
                }
            }, this.seconds);
        },
        formatSeconds(sec) {
            return sec.toString().padStart(2, '0');
        },
        reset(minute) {
            this.min = minute;
            this.sec = 0;
        },
        typing(value) {
            this.disabled = !(value.length == this.length);
        },
        complete(value) {
            this.value = value;
        },
        validate() {
            let that = this;

            that.$emit('showloader');
            OTP.validate(this.type, this.pin, this.value).then(function(response) {
                let data = response.data.data;

                that.$emit('success', data);
                that.$emit('hideloader');
            }).catch(function(error) {
                that.value = '';
                that.$emit('failed', error.response.data.message);
                that.$emit('hideloader');
            });
        },
        resend() {
            let that = this;

            that.$emit('showloader');
            OTP.request(this.type, this.name, this.email, this.mobile, this.pin).then(function() {
                that.show = false;
                that.reset(that.minutes);
                that.$emit('reload');
                that.$emit('hideloader');
                that.startTimer();
            }).catch(function(error) {
                that.value = '';
                that.$emit('failed', error.response.data.message);
                that.$emit('hideloader');
            });
        },
    }
}
</script>
