<template>
    <div>
        <VoteSection @error="error"
            @alert="alert"
            @preload="preload"
            @loaded="loaded"
            v-bind:title="lang('lbl_0000173')"
            v-bind:subtitle="lang('lbl_0000175')"
            v-bind:notice="lang('lbl_0000187')"
            :max="1"
            id="elecom_ep"
            category="elecom"
            type="ep" 
            href="/ga/election/vote/summary"
            color="bg-theme-elecom"
            backable />
    </div>
</template>

<script>
import VoteSection from './../../components/sections/ElectionVoteSection.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        VoteSection,
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', true);
        this.$emit('shownavbar', {
            show: false,
        });

        let state = this.$store.state.ga;

        if(!state.registered || state.voted) {
            this.$router.push('/dashboard');
        }
    },
    methods: {
        alert(config) {
            this.$emit('alert', config);
        },
        error(error) {
            this.$emit('error', error);
        },
        preload() {
            this.$emit('showloader');
        },
        loaded() {
            this.$emit('hideloader');
        }
    }
}
</script>