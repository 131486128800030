<template>
    <div>
        <PromptControl @ok="ok"
            @closed="closed"
            @cancel="cancel"
            v-bind:show="prompt.show"
            v-bind:title="prompt.title"
            v-bind:message="prompt.message" />
        <ContentWrapperControl>
            <div class="bg-white p-4 rounded-b shadow overflow-hidden mb-4">
                <div v-text="lang('lbl_0000060')" class="text-sm leading-normal text-grey-darkest"></div>
            </div>
            <div class="bg-white p-4 rounded shadow">
                <div class="mb-4">
                    <TextControl @typing="typing" 
                        v-bind:value="old_password"
                        v-bind:placeholder="lang('lbl_0000061')"
                        name="old_password"
                        type="password"
                        required />
                </div>
                <div class="mb-4">
                    <TextControl @typing="typing" 
                        @strong="strong"
                        v-bind:value="new_password"
                        v-bind:placeholder="lang('lbl_0000062')"
                        name="new_password" 
                        type="password" 
                        required
                        strength />
                </div>
                <div class="mb-4">
                    <TextControl @typing="typing" 
                        v-bind:value="con_password"
                        v-bind:placeholder="lang('lbl_0000063')"
                        name="con_password" 
                        type="password" 
                        required />
                </div>
                <div>
                    <ButtonControl v-on:click="update" v-bind:disabled="disabled">
                        {{ lang('lbl_0000050') }}
                    </ButtonControl>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from 'vue-router';
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import PromptControl from './../../components/controls/PromptControl.vue';
import TextControl from './../../components/controls/TextControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        PromptControl,
        TextControl,
    },
    data() {
        return {
            href: null,
            changed: false,
            disabled: true,
            passed: false,
            old_password: '',
            new_password: '',
            con_password: '',
            prompt: {
                show: false,
                title: null,
                message: null,
            },
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000064'),
            show: true,
            back: true,
        });

        let that = this;

        onBeforeRouteLeave((to) => {
            that.href = to.fullPath;

            if(that.changed) {
                that.prompt.title = that.lang('lbl_0000056');
                that.prompt.message = that.lang('lbl_0000057');
                that.prompt.show = true;

                return false;
            }
        });
    },
    methods: {
        strong(passed) {
            this.passed = passed;
        },
        ok() {
            this.changed = false;
            this.$router.push(this.href);
        },
        cancel() {
            this.prompt.show = false;
        },
        closed() {
            this.prompt.show = false;
        },
        typing(obj) {
            this[obj.name] = obj.value;
            this.enableButton();
        },
        update() {
            let that = this,
                state = this.$store.state;

            this.$emit('showloader');
            User.updatePassword(state.uuid, this.old_password, this.new_password, this.con_password).then(function() {
                that.reset();
                that.disabled = true;
                that.changed = false;
                that.$emit('hideloader');
                that.$emit('success', that.lang('lbl_0000065'));
            }).catch(function(error) {
                that.reset();
                that.$emit('error', error);
            });
        },
        reset() {
            this.old_password = '';
            this.new_password = '';
            this.con_password = '';
        },
        enableButton() {
            this.disabled = (this.old_password.length == 0 || this.new_password.length == 0 || this.con_password.length == 0 || !this.passed);
            this.changed = (this.old_password.length != 0 || this.new_password.length != 0 || this.con_password.length != 0);
        },
    }
}
</script>