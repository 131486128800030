<template>
    <div>
        <PromptControl @ok="ok"
            @cancel="cancel"
            @closed="closed"
            v-bind:show="show"
            v-bind:title="lang('lbl_0000056')"
            v-bind:message="lang('lbl_0000057')" />
        <ContentWrapperControl>
            <div v-show="verify" class="bg-white p-4 rounded-b shadow overflow-hidden">
                <div v-text="lang('lbl_0000076')" class="text-sm italic leading-normal text-grey-darkest"></div>
            </div>
            <div class="bg-white p-4 mt-4 rounded shadow">
                <div v-show="!verify">
                    <div class="mb-3">
                        <TextControl @typing="typing" 
                            v-bind:value="value"
                            name="email" 
                            type="text" />
                    </div>
                    <div>
                        <ButtonControl v-on:click="update" v-bind:disabled="disabled">
                            {{ lang('lbl_0000050') }}
                        </ButtonControl>
                    </div>
                </div>
                <div v-show="verify">
                    <OTPControl @success="success"
                        @failed="failed"
                        @showloader="showloader"
                        @hideloader="hideloader"
                        v-bind:type="otp.type"
                        v-bind:pin="otp.pin"
                        v-bind:email="value"
                        v-bind:name="name" />
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from 'vue-router';
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import OTPControl from './../../components/controls/OTPControl.vue';
import PromptControl from './../../components/controls/PromptControl.vue';
import TextControl from './../../components/controls/TextControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        OTPControl,
        PromptControl,
        TextControl,
    },
    data() {
        return {
            value: '',
            verify: false,
            saving: false,
            disabled: true,
            changed: false,
            show: false,
            name: null,
            href: null,
            hash: null,
            otp: {
                type: 6,
                token: null,
                pin: null,
            },
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000075'),
            show: true,
            back: true,
        });

        let that = this;

        onBeforeRouteLeave((to) => {
            that.href = to.fullPath;

            if(that.changed) {
                that.show = true;

                return false;
            }
        });

        this.value = this.$store.state.info.email || '';
        this.name = this.$store.state.info.first_name || '';
    },
    methods: {
        success(data) {
            let that = this,
                state = this.$store.state;

            this.saving = true;
            this.otp.token = data.otp_token;
            User.updateEmail(state.uuid, {
                value: this.value,
                otp_token: this.otp.token,
                otp_pin: this.otp.pin,
                update_key: this.hash,
            }).then(function() {
                state.info.email = that.value;
                that.reset();
                that.$emit('success', that.lang('lbl_0000077'));
            }).catch(function(error) {
                that.reset();
                that.$emit('error', error);
            });
        },
        failed(message) {
            this.$emit('alert', {
                theme: 'error',
                title: this.lang('lbl_0000034'),
                message: message,
            });
        },
        reset() {
            this.saving = false;
            this.verify = false;
            this.changed = false;
            this.disabled = true;
            this.hash = null;
            this.otp.token = null;
            this.otp.pin = null;
        },
        ok() {
            this.changed = false;
            this.$router.push(this.href);
        },
        closed() {
            this.show = false;
        },
        cancel() {
            this.show = false;
        },
        typing(obj) {
            this.value = obj.value;
            this.enableButton();
        },
        enableButton() {
            this.disabled = this.value.length == 0 || (this.value == this.$store.state.info.email);
            this.changed = this.value != this.$store.state.info.email;
        },
        update() {
            let that = this,
                state = this.$store.state;

            this.$emit('showloader');
            User.validateEmail(state.uuid, this.value).then(function(response) {
                let data = response.data.data;

                that.hash = data.hash;
                that.otp.type = data.type;
                that.otp.pin = data.pin;
                that.verify = true;
                that.$emit('hideloader');
            }).catch(function(error) {
                that.hash = null;
                that.otp.type = null;
                that.otp.pin = null;
                that.verify = false;
                that.$emit('error', error);
            });
        },
        showloader() {
            this.$emit('showloader');
        },
        hideloader() {
            if (!this.saving) {
                this.$emit('hideloader');
            }
        },
    },
}
</script>