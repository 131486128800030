<template>
    <div>
        <ContentWrapperControl class="mt-4">
            <div class="bg-white shadow rounded p-4 text-sm leading-normal">
                <div class="text-lg font-bold pb-2 border-b mb-4">I. INTRODUCTION</div>
                <div class="pb-4">This Privacy Policy is adopted in compliance <b>Republic Act No. 10173</b>, otherwise known as the “<b>Data Privacy Act of 2012</b>” (DPA), its Implementing Rules and Regulations (IRR), and other relevant rules and policies, including issuances of the National Privacy Commission (NPC).</div>
                <div class="pb-6">This policy outlines the data protection and security measures adopted by the Philippine Army Finance Center Producers Integrated Cooperative (PAFCPIC) to safeguard and protect data privacy rights and shall serve as a guide in the exercise of rights under the DPA.</div>
                <div class="text-lg font-bold pb-2 border-b mb-4">II. POLICY STATEMENT</div>
                <div class="pb-6">We, at Philippine Army Finance Center Producers Integrated Cooperative (PAFCPIC), are committed to provide you with quality and innovative services while implementing safeguards to protect your privacy and keep your personal information safe and secure in compliance with the DPA. All officers and employees of PAFCPIC are enjoined to comply with and to share the responsibility to secure and protect personal information, including sensitive personal information, collected and processed by the Cooperative in pursuit of legitimate purposes.</div>
                <div class="text-lg font-bold pb-2 border-b mb-4">III. DEFINITIONS OF TERMS</div>
                <div class="pb-2">A. Data Subject refers to an individual whose personal, sensitive personal, or privileged information is processed.</div>
                <div class="pb-2">B. Personal information refers to any information whether recorded in a material form or not, from which the identity of an individual is apparent or can be reasonably and directly ascertained by the entity holding the information, or when put together with other information would directly and certainly identify an individual.</div>
                <div class="pb-2">C. Sensitive personal information refers to personal information:</div>
                <div class="pb-2 pl-4 italic">1. About an individual’s race, ethnic origin, marital status, age, color, and religious, philosophical or political affiliations;</div>
                <div class="pb-2 pl-4 italic">2. About an individual’s health, education, genetic or sexual life of a person, or to any proceeding for any offense committed or alleged to have been committed by such person, the disposal of such proceedings, or the sentence of any court in such proceedings;</div>
                <div class="pb-2 pl-4 italic">3. Issued by government agencies peculiar to an individual which includes, but not limited to, social security numbers, previous or cm-rent health records, licenses or its denials, suspension or revocation, and tax returns; and</div>
                <div class="pb-4 pl-4 italic">4. Specifically established by an executive order or an act of Congress to be kept classified.</div>
                <div class="pb-2">D. Processing refers to any operation or any set of operations performed upon personal information including, but not limited to, the collection, recording, organization, storage, updating or modification, retrieval, consultation, use, consolidation, blocking, erasure or destruction of data.</div>
                <div class="pb-2">E. Personal information controller refers to a person or organization who controls the collection, holding, processing or use of personal information, including a person or organization who instructs another person or organization to collect, hold, process, use, transfer or disclose personal information on his or her behalf.</div>
                <div class="pb-6">F. Data Protection Officer refers to an individual designated by the head of agency or organization to be accountable for its compliance with the DPA, its IRR, and other issuances of the NPC.</div>
                <div class="text-lg font-bold pb-2 border-b mb-4">IV. PROCESSING OF PERSONAL INFORMATION</div>
                <div class="font-bold pb-2">1. Collection of Personal Information</div>
                <div class="pb-2">In the course of our transactions with data subjects, PAFCPIC may collect personal information including, but not limited to:</div>
                <div class="pb-2 italic">1. Name, sex, civil status, residential address, date and place of birth, age, citizenship.</div>
                <div class="pb-2 italic">2. Parents’ name, religion, nationality, date and place of birth, occupation, and other information in Birth Certificate.</div>
                <div class="pb-2 italic">3. Military serial number, rank, Tax Identification Number (TIN), GSIS or SSS number.</div>
                <div class="pb-2 italic">4. Employment status, occupation, service term, employer, office address.</div>
                <div class="pb-2 italic">5. Monthly and annual income, financial situation.</div>
                <div class="pb-2 italic">6. Contact numbers, e-mail address, and emergency contact information.</div>
                <div class="pb-2 italic">7. Spouse’s name, date and place of birth, residence, citizenship, religion, parents.</div>
                <div class="pb-2 italic">8. Date and place of marriage, solemnizing officer, and other information in Marriage Certificate.</div>
                <div class="pb-2 italic">9. Name of legal beneficiaries and their personal information.</div>
                <div class="pb-2 italic">10. Medical condition and history.</div>
                <div class="pb-2 italic">11. Specimen signatures.</div>
                <div class="pb-2 italic">12. Scholastic and other academic records.</div>
                <div class="pb-2 italic">13. Date and place of death, cause of death, and other information in Death Certificate.</div>
                <div class="pb-6 italic">14. Images via CCTV and other similar recordings taken within office premises or during activities conducted out of office.</div>
                <div class="pb-2">A data subject’s personal information may be collected through the following ways:</div>
                <div class="pb-2 italic">1. Application for membership by filling out the required forms and submitting additional requirements.</div>
                <div class="pb-2 italic">2. Application for loans or other financial and integrated services by filling out the required forms and submitting additional requirements.</div>
                <div class="pb-2 italic">3. Interaction with our employees, either written or verbal, through face-to-face transactions, phone, e-mail, or social media inquiries.</div>
                <div class="pb-2 italic">4. Filling out of the required forms for inquiries, requests, comments, or complaints.</div>
                <div class="pb-2 italic">5. Participation in surveys, focus group discussions, marketing promotions, and other similar initiatives.</div>
                <div class="pb-2 italic">6. Submission of requirements for job application.</div>
                <div class="pb-2 italic">7. Submission of requirements for scholarship or other requests for assistance.</div>
                <div class="pb-2 italic">8. Recording of images and videos through CCTV within office premises.</div>
                <div class="pb-2 italic">9. Application for voluntary termination or deceased termination filed by legal beneficiaries by filling out the required forms and submitting additional requirements.</div>
                <div class="pb-6 italic">10. Other similar means.</div>
                <div class="font-bold pb-2">2. Use and Disclosure of Personal Information</div>
                <div class="pb-2">PAFCPIC uses and discloses collected personal information for the following purposes:</div>
                <div class="pb-2 italic">1. To manage membership of individuals in the cooperative.</div>
                <div class="pb-2 italic">2. To provide quality financial and integrated products and services.</div>
                <div class="pb-2 italic">3. To provide assistance and feedback to members.</div>
                <div class="pb-2 italic">4. To communicate relevant services, advisories, promotions, including responses to queries, requests, and complaints.</div>
                <div class="pb-2 italic">5. To allow members to participate in promotional activities, special events, and community initiatives.</div>
                <div class="pb-2 italic">6. To undertake marketing and promotional campaigns.</div>
                <div class="pb-2 italic">7. To implement prevention, detection, and corrective measures in relation to fraud and other relevant risks.</div>
                <div class="pb-2 italic">8. To evaluate and process applications for employment.</div>
                <div class="pb-2 italic">9. To comply with the requirements of Republic Act 9510 (“Credit Information System Act”).</div>
                <div class="pb-2 italic">10. To comply with the requirements of other relevant existing laws and legal proceedings such as court orders.</div>
                <div class="pb-2 italic">11. To comply with the legal obligation to prevent imminent harm to public security, safety, or order.</div>
                <div class="pb-2 italic">12. To utilize relevant data for research, analytical, and promotional purposes.</div>
                <div class="pb-6 italic">13. To undertake other similar legitimate purposes.</div>
                <div class="font-bold pb-2">3. Sharing of Personal Information to Third Parties</div>
                <div class="pb-6">In the course of operations and providing services to members, PAFCPIC may engage the services of third parties. In instances when certain personal information is required to be disclosed to fulfill legitimate business concerns and as may be necessary to provide services, we shall exercise utmost diligence in processing your personal information. PAFCPIC will never share, sell, or otherwise disclose your personal information with third parties, except as otherwise indicated above, or unless otherwise permitted under the Data Privacy Act or other applicable laws and regulations.</div>
                <div class="font-bold pb-2">4. Accessing and Updating your Personal Information</div>
                <div class="pb-6">Members may access and update personal information previously collected from them by proceeding to any of our PAFCPIC Satellite Offices (PSOs) and submitting a written request to authorized employees. This information will be available for members’ access during business hours and after a reasonable period of time for us to prepare the same.</div>
                <div class="font-bold pb-2">5. Personal Information Retention and Disposal</div>
                <div class="pb-4">The personal information of data subjects shall be retained by PAFCPIC only for as long as it is necessary to fulfill the purpose/s for which it was obtained and in accordance with the prescribed retention period depending on the nature of the personal information collected and subject to the specific retention policy of the department or section storing such information.</div>
                <div class="pb-6">Thereafter, the personal information shall be disposed of or de-identified in a secure manner sufficient to make the information non-personally identifiable in order to prevent further processing, unauthorized access, or unlawful disclosure to any other party.</div>
                <div class="text-lg font-bold pb-2 border-b mb-4">V. DATA PROTECTION</div>
                <div class="pb-4">PAFCPIC implements reasonable and appropriate organizational, physical, and technical security measures for the protection of personal information which we collected.</div>
                <div class="pb-4">The security measures aim to maintain the availability, integrity, and confidentiality of personal information and are intended for the protection of personal information against any accidental or unlawful destruction, alteration, and disclosure, as well as against any other unlawful processing.</div>
                <div class="pb-6">In compliance with the DPA, PAFCPIC has appointed a Data Protection Officer (DPO) to manage and safeguard the processing of your personal information. A Data Privacy Section under the direct supervision of the DPO was also established for the same purpose. PAFCPIC commits itself to ensuring that your personal information is secured and protected under the standards provided for by law and in line with the best practices of the industry.</div>
                <div class="text-lg font-bold pb-2 border-b mb-4">VI. CONFIDENTIALITY</div>
                <div class="pb-6">PAFCPIC employees process and hold personal information under strict confidentiality. They are required to sign non-disclosure agreements and are trained on the company’s privacy and security policies to ensure confidentiality and security of your personal information.</div>
                <div class="text-lg font-bold pb-2 border-b mb-4">VII. RIGHTS OF THE DATA SUBJECT</div>
                <div class="pb-2">Data subjects are entitled to the following rights:</div>
                <div class="pb-2">A. Be informed of when and how personal information shall be, is being or has been processed;</div>
                <div class="pb-2">B. Be furnished with the information indicated below before the entry of personal information into the processing system of the personal information controller, or at the next practical opportunity:</div>
                <div class="pb-2 pl-4 italic">1. Description of the personal information to be entered into the system;</div>
                <div class="pb-2 pl-4 italic">2. Purposes for which it is being or are to be processed;</div>
                <div class="pb-2 pl-4 italic">3. Scope and method of the personal information processing;</div>
                <div class="pb-2 pl-4 italic">4. The recipients or classes of recipients to whom it is or may be disclosed;</div>
                <div class="pb-2 pl-4 italic">5. Methods utilized for automated access, if the same is allowed by the data subject, and the extent to which such access is authorized;</div>
                <div class="pb-2 pl-4 italic">6. The identity and contact details of the personal information controller or its representative;</div>
                <div class="pb-2 pl-4 italic">7. The period for which the information will be stored; and</div>
                <div class="pb-4 pl-4 italic">8. The existence of their rights, i.e., to access, correction, as well as the right to lodge a complaint before the Commission.</div>
                <div class="pb-6">Any information supplied or declaration made to the data subject on these matters shall not be amended without prior notification: Provided, That the notification under subsection (b) shall not apply should the personal information be needed pursuant to a subpoena or when the collection and processing are for reasonably apparent purposes, including when it is necessary for the performance of or in relation to a contract or service or when necessary or desirable in the context of an employer-employee relationship, or when the information is being collected and processed as a result of legal obligation;</div>
                <div class="pb-2">C.Reasonable access to, upon demand, the following:</div>
                <div class="pb-2 pl-4 italic">1. Contents of personal information that were processed;</div>
                <div class="pb-2 pl-4 italic">2. Sources from which personal information were obtained;</div>
                <div class="pb-2 pl-4 italic">3. Names and addresses of recipients of the personal information and sensitive personal information;</div>
                <div class="pb-2 pl-4 italic">4. Manner by which such data were processed;</div>
                <div class="pb-2 pl-4 italic">5. Reasons for the disclosure of the personal information to recipients;</div>
                <div class="pb-2 pl-4 italic">6. Information on automated processes where the data will or likely to be made as the sole basis for any decision significantly affecting or will affect the data subject;</div>
                <div class="pb-2 pl-4 italic">7. Date when personal information was last accessed and modified; and</div>
                <div class="pb-6 pl-4 italic">8. The designation, or name or identity and address of the personal information controller (PIC).</div>
                <div class="pb-2">D. Dispute the inaccuracy or error in the personal information and have the personal information controller correct it immediately and accordingly, unless the request is vexatious or otherwise unreasonable. If the personal information has been corrected, the PIC shall ensure the accessibility of both the new and the retracted information and the simultaneous receipt of the new and the retracted information by recipients thereof: Provided, That the third parties who have previously received such processed personal information shall be informed of its inaccuracy and its rectification upon request of the data subject;</div>
                <div class="pb-2">E. Suspend, withdraw or order the blocking, removal or destruction of personal information from the PIC’s filing system upon discovery and substantial proof that the personal information are incomplete, outdated, false, unlawfully obtained, used for unauthorized purposes or are no longer necessary for the purposes for which they were collected; and</div>
                <div class="pb-2">F. Be indemnified for any damages sustained due to such inaccurate, incomplete, outdated, false, unlawfully obtained or unauthorized use of personal information.</div>
                <div class="pb-6">G. Where personal information is processed by electronic means and in a structured and commonly used format, data subjects have the right to obtain from the PIC a copy of data undergoing processing in an electronic or structured format, which is commonly used and allows for further use.</div>
                <div class="text-lg font-bold pb-2 border-b mb-4">VIII. AMENDMENTS AND UPDATES TO PRIVACY POLICY</div>
                <div class="pb-6">PAFCPIC reserves the right to regularly update and amend its Privacy Policy, as needed, to comply with legal and regulatory requirements, to reflect changes in the Cooperative’s processes and procedures, to align with industry practice, to adopt new technologies and methodologies, or for other legitimate purposes. Any significant updates or amendments to the Privacy Policy shall be duly communicated and disseminated to all concerned parties.</div>
                <div class="text-lg font-bold pb-2 border-b mb-4">IX. CONTACT INFORMATION</div>
                <div class="pb-6">If you have further questions or concerns, you may contact our Data Protection Officer through the following details:</div>
                <div class="pb-2">Contact Number: (02) 8844-9010 loc. 310<br />Email Address: dpo.pafcpic@gmail.com</div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
    },
    mounted() {
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000135'),
            show: true,
            back: true,
        });
    },
}
</script>