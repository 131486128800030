<template>
    <div>
        <ContentWrapperControl>
            <div class="bg-white mb-4 rounded-b shadow">
                <div class="p-4 text-sm">
                    <div v-text="lang('lbl_0000166')" class="leading-tight text-grey-darkest"></div>
                </div>
            </div>
            <div class="bg-white overflow-hidden mt-4 rounded shadow mb-4 leading-normal">
                <div class="flex px-4 pt-4">
                    <div class="font-bold text-md flex items-center justify-center leading-normal">
                        <span v-text="lang('lbl_0000209')"></span>
                    </div>
                    <div class="flex-grow"></div>
                    <div v-on:click="peek" class="flex items-center justify-center">
                        <img v-bind:src="getIcon()" />
                    </div>
                </div>
                <div>
                    <NumberInputControl @typing="typing" 
                        v-bind:value="otp" 
                        v-bind:hidden="hidden"
                        :length="6" />
                </div>
                <div class="px-4 pb-4">
                    <ButtonControl v-on:click="proceed" v-bind:disabled="disabled">
                        {{ lang('lbl_0000015') }}
                    </ButtonControl>
                </div>
                <div v-show="resend" class="text-center text-sm pb-4 underline text-blue">
                    <span v-on:click="resendOtp" v-text="lang('lbl_0000018')"></span>
                </div>
                <div v-show="!resend" class="text-center text-sm pb-4 text-grey-darker">
                    <span v-text="lang('lbl_0000019')"></span> <span v-text="min"></span>:<span v-text="formatSeconds(sec)"></span>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import NumberInputControl from './../../components/controls/NumberInputControl.vue';
import GA from './../../services/GA.js';
import OTP from './../../services/OTP.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        NumberInputControl,
    },
    data() {
        return {
            otp: '',
            disabled: true,
            resend: true,
            type: 5,
            name: '',
            mobile: '',
            email: '',
            pin: null,
            min: 2,
            sec: 0,
            seconds: 1000,
            minutes: 2,
            t: null,
            hidden: true,
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000165'),
            show: true,
            back: true,
        });

        let state = this.$store.state;

        if (!state.ga.can_vote || !state.ga.voting.started || state.ga.voted) {
            this.$router.push('/dashboard');
        }

        this.name = state.info.first_name;
        this.mobile = state.info.mobile_no;
        this.email = state.info.email;
    },
    methods: {
        peek() {
            this.hidden = !this.hidden;
        },
        getIcon() {
            return require(!this.hidden ? "@/assets/icons/papsi_password_visible.svg" : "@/assets/icons/papsi_password_invisible.svg");
        },
        startTimer() {
            let that = this;

            this.t = setInterval(function() {
                if (that.min == 0 && that.sec == 0) {
                    that.resend = true;
                    that.reset(that.minutes);
                    clearInterval(that.t);
                }
                else {
                    if (that.sec == 0) {
                        that.min--;
                        that.sec = 59;
                    }
                    else {
                        that.sec--;
                    }
                }
            }, this.seconds);
        },
        formatSeconds(sec) {
            return sec.toString().padStart(2, '0');
        },
        reset(minute) {
            this.min = minute;
            this.sec = 0;
        },
        typing(otp) {
            this.otp = otp;
        },
        proceed() {
            let that = this;

            this.$emit('showloader');
            GA.validateOtp(this.otp, this.$store.state.ga.code).then(function(response) {
                that.$store.state.vote.secret_key = response.data.data.secret_key;
                that.$emit('hideloader');
                that.$router.push('/ga/election/policy');
            }).catch(function(error) {
                that.otp = '';
                that.$emit('error', error);
            });
        },
        resendOtp() {
            let that = this;

            this.$emit('showloader');
            OTP.request(this.type, this.name, this.email, this.mobile, this.pin).then(function(response) {
                that.resend = false;
                that.pin = response.data.data.pin;
                that.otp = '';
                that.reset(that.minutes);
                that.$emit('hideloader');
                that.startTimer();
            }).catch(function(error) {
                that.$emit('error', error);
            });
        },
    },
    watch: {
        otp(value) {
            this.disabled = value.length != 6;
        },
    }
}
</script>