import { createRouter, createWebHistory } from 'vue-router'
import AuthenticationSettingView from '../views/user/AuthenticationSettingView.vue'
import ContactUsView from '../views/static/ContactUsView.vue'
import DashboardView from '../views/DashboardView.vue'
import DeactivateView from '../views/user/DeactivateAccountView.vue'
import DeactivateVerifyView from '../views/user/DeactivateVerifyView.vue'
import DeactivateFinalView from '../views/user/DeactivateFinalView.vue'
import DeactivatedUserView from '../views/user/DeactivatedUserView.vue'
import ElectionAuthView from '@/views/election/ElectionAuthView.vue'
import ElectionPolicyView from '../views/election/ElectionPolicyView.vue'
import ElectionRatingsView from '../views/election/ElectionRatingsView.vue'
import ElectionVoteAuditView from '../views/election/ElectionVoteAuditView.vue'
import ElectionVoteBallotView from '../views/election/ElectionVoteBallotView.vue'
import ElectionVoteBODView from '../views/election/ElectionVoteBODView.vue'
import ElectionVoteElecomOfficerView from '../views/election/ElectionVoteElecomOfficerView.vue'
import ElectionVoteElecomEPView from '../views/election/ElectionVoteElecomEPView.vue'
import ElectionVoteSummaryView from '../views/election/ElectionVoteSummaryView.vue'
import EmailSettingView from '../views/user/UpdateEmailSettingView.vue'
import EnrollPinView from '@/views/user/EnrollPinView.vue'
import EndUserLicenseAgreementView from '../views/static/EndUserLicenseAgreementView.vue'
import ForgotPasswordSuccessView from '../views/user/ForgotPasswordSuccessView.vue'
import ForgotPasswordView from '../views/user/ForgotPasswordView.vue'
import ForgotPasswordUpdateView from '../views/user/ForgotPasswordUpdateView.vue'
import ForgotPasswordVerifyView from '../views/user/ForgotPasswordVerifyView.vue'
import ForgotPasswordOTPView from '@/views/user/ForgotPasswordOTPView.vue'
import NeedHelpView from '../views/NeedHelpView.vue'
import OurBranchesView from '../views/static/OurBranchesView.vue'
import OurCompanyView from '../views/static/OurCompanyView.vue'
import PasswordSettingView from '../views/user/UpdatePasswordSettingView.vue'
import PrivacyPolicyView from '../views/static/PrivacyPolicyView.vue'
import ProfileView from '../views/ProfileView.vue'
import LoginView from '../views/LoginView.vue'
import LoginHistoryView from '../views/user/LoginHistoryView.vue'
import MobileSettingView from '../views/user/UpdateMobileSettingView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import RegisterEmailVerifyView from '../views/RegisterEmailVerifyView'
import RegisterMobileVerifyView from '../views/RegisterMobileVerifyView.vue'
import RegisterPinEnrollView from '../views/RegisterPinEnrollView'
import RegisterPinConfirmView from '../views/RegisterPinConfirmView'
import RegisterVerifyView from '../views/RegisterVerifyView.vue'
import RegisterSuccessView from '../views/RegisterSuccessView.vue'
import RegisterView from '../views/RegisterView.vue'
import SettingsView from '../views/user/SettingsView.vue'
import UpdatePinSettingView from '../views/user/UpdatePinSettingView.vue'
import UpdateInfoView from '../views/user/UpdateInfoView.vue'
import UpdateTinInfoView from '../views/user/UpdateTinInfoView.vue'
import UsernameSettingView from '../views/user/UpdateUsernameSettingView.vue'

const routes = [
  {
    path: '/',
    name: 'login-page',
    component: LoginView,
    meta: {
      title: 'PAPSI Mobile - Log In',
    }
  },
  {
    path: '/contact-us',
    name: 'contact-us-page',
    component: ContactUsView,
    meta: {
      title: 'PAPSI Mobile - Contact Us',
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard-page',
    component: DashboardView,
    meta: {
      title: 'PAPSI Mobile - Dashboard',
    }
  },
  {
    path: '/ga/election/auth',
    name: 'election-auth-page',
    component: ElectionAuthView,
    meta: {
      title: 'PAPSI Mobile - Election Auth',
    }
  },
  {
    path: '/ga/election/policy',
    name: 'election-policy-page',
    component: ElectionPolicyView,
    meta: {
      title: 'PAPSI Mobile - Election Policy',
    }
  },
  {
    path: '/ga/election/vote/bod',
    name: 'election-vote-bod-page',
    component: ElectionVoteBODView,
    meta: {
      title: 'PAPSI Mobile - Vote',
    }
  },
  {
    path: '/ga/election/vote/audit',
    name: 'election-vote-audit-page',
    component: ElectionVoteAuditView,
    meta: {
      title: 'PAPSI Mobile - Vote',
    }
  },
  {
    path: '/ga/election/vote/elecom/officer',
    name: 'election-vote-elecom-of-page',
    component: ElectionVoteElecomOfficerView,
    meta: {
      title: 'PAPSI Mobile - Vote',
    }
  },
  {
    path: '/ga/election/ballot',
    name: 'election-vote-ballot-page',
    component: ElectionVoteBallotView,
    meta: {
      title: 'PAPSI Mobile - Ballot',
    }
  },
  {
    path: '/ga/election/vote/elecom/enlisted-personnel',
    name: 'election-vote-elecom-ep-page',
    component: ElectionVoteElecomEPView,
    meta: {
      title: 'PAPSI Mobile - Vote',
    }
  },
  {
    path: '/ga/election/ratings',
    name: 'election-ratings-page',
    component: ElectionRatingsView,
    meta: {
      title: 'PAPSI Mobile - Rating',
    }
  },
  {
    path: '/ga/election/vote/summary',
    name: 'election-vote-summary-page',
    component: ElectionVoteSummaryView,
    meta: {
      title: 'PAPSI Mobile - Vote Summary',
    }
  },
  {
    path: '/end-user-license-agreement',
    name: 'end-user-license-agreement-page',
    component: EndUserLicenseAgreementView,
    meta: {
      title: 'PAPSI Mobile - End User License Agreement',
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password-page',
    component: ForgotPasswordView,
    meta: {
      title: 'PAPSI Mobile - Forgot Password',
    }
  },
  {
    path: '/forgot-password/success',
    name: 'forgot-password-success-page',
    component: ForgotPasswordSuccessView,
    meta: {
      title: 'PAPSI Mobile - Forgot Password',
    }
  },
  {
    path: '/forgot-password/verify',
    name: 'forgot-password-verify-page',
    component: ForgotPasswordVerifyView,
    meta: {
      title: 'PAPSI Mobile - Forgot Password',
    }
  },
  {
    path: '/forgot-password/verify/otp',
    name: 'forgot-password-verify-otp-page',
    component: ForgotPasswordOTPView,
    meta: {
      title: 'PAPSI Mobile - Forgot Password',
    }
  },
  {
    path: '/forgot-password/update',
    name: 'forgot-password-update-page',
    component: ForgotPasswordUpdateView,
    meta: {
      title: 'PAPSI Mobile - Forgot Password',
    }
  },
  {
    path: '/help',
    name: 'need-help-page',
    component: NeedHelpView,
    meta: {
      title: 'PAPSI Mobile - Need Help?',
    }
  },
  {
    path: '/satellite-offices',
    name: 'our-branches-page',
    component: OurBranchesView,
    meta: {
      title: 'PAPSI Mobile - Satellite Offices',
    }
  },
  {
    path: '/our-company',
    name: 'our-company-page',
    component: OurCompanyView,
    meta: {
      title: 'PAPSI Mobile - Our Company',
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy-page',
    component: PrivacyPolicyView,
    meta: {
      title: 'PAPSI Mobile - Privacy Policy',
    }
  },
  {
    path: '/profile',
    name: 'profile-page',
    component: ProfileView,
    meta: {
      title: 'PAPSI Mobile - Profile',
    }
  },
  {
    path: '/signup',
    name: 'create-account-page',
    component: RegisterView,
    meta: {
      title: 'PAPSI Mobile - Create an Account',
    }
  },
  {
    path: '/signup/verify',
    name: 'create-account-verify-page',
    component: RegisterVerifyView,
    meta: {
      title: 'PAPSI Mobile - Verify Account',
    }
  },
  {
    path: '/signup/verify/email',
    name: 'create-account-verify-email-page',
    component: RegisterEmailVerifyView,
    meta: {
      title: 'PAPSI Mobile - Verify Account',
    }
  },
  {
    path: '/signup/verify/mobile',
    name: 'create-account-verify-mobile-page',
    component: RegisterMobileVerifyView,
    meta: {
      title: 'PAPSI Mobile - Verify Account',
    }
  },
  {
    path: '/signup/pin',
    name: 'create-account-enroll-pin-page',
    component: RegisterPinEnrollView,
    meta: {
      title: 'PAPSI Mobile - Verify Account',
    }
  },
  {
    path: '/signup/pin/confirm',
    name: 'create-account-confirm-pin-page',
    component: RegisterPinConfirmView,
    meta: {
      title: 'PAPSI Mobile - Verify Account',
    }
  },
  {
    path: '/signup/done',
    name: 'create-account-done-page',
    component: RegisterSuccessView,
    meta: {
      title: 'PAPSI Mobile - Create an Account',
    }
  },
  {
    path: '/settings',
    name: 'settings-page',
    component: SettingsView,
    meta: {
      title: 'PAPSI Mobile - Settings',
    }
  },
  {
    path: '/settings/password',
    name: 'password-setting-page',
    component: PasswordSettingView,
    meta: {
      title: 'PAPSI Mobile - Edit Password',
    }
  },
  {
    path: '/settings/username',
    name: 'username-setting-page',
    component: UsernameSettingView,
    meta: {
      title: 'PAPSI Mobile - Edit Username',
    }
  },
  {
    path: '/settings/mobile',
    name: 'mobile-setting-page',
    component: MobileSettingView,
    meta: {
      title: 'PAPSI Mobile - Edit Mobile',
    }
  },
  {
    path: '/settings/email',
    name: 'email-setting-page',
    component: EmailSettingView,
    meta: {
      title: 'PAPSI Mobile - Edit Email',
    }
  },
  {
    path: '/settings/security/authentication',
    name: 'auth-security-page',
    component: AuthenticationSettingView,
    meta: {
      title: 'PAPSI Mobile - Authentication Settings',
    }
  },
  {
    path: '/settings/security/login-history',
    name: 'login-history-page',
    component: LoginHistoryView,
    meta: {
      title: 'PAPSI Mobile - Login History',
    }
  },
  {
    path: '/settings/deactivate',
    name: 'deactivate-account-page',
    component: DeactivateView,
    meta: {
      title: 'PAPSI Mobile - Deactivate Account',
    }
  },
  {
    path: '/settings/deactivate/verify',
    name: 'deactivate-account-verify-page',
    component: DeactivateVerifyView,
    meta: {
      title: 'PAPSI Mobile - Deactivate Account',
    }
  },
  {
    path: '/settings/deactivate/final',
    name: 'deactivate-account-final-page',
    component: DeactivateFinalView,
    meta: {
      title: 'PAPSI Mobile - Deactivate Account',
    }
  },
  {
    path: '/user/deactivated',
    name: 'deactivated-user-page',
    component: DeactivatedUserView,
    meta: {
      title: 'PAPSI Mobile - Deactivated Account',
    }
  },
  {
    path: '/settings/info',
    name: 'settings-info-page',
    component: UpdateInfoView,
    meta: {
      title: 'PAPSI Mobile - Member Info',
    }
  },
  {
    path: '/settings/info/tin',
    name: 'update-tin-info-page',
    component: UpdateTinInfoView,
    meta: {
      title: 'PAPSI Mobile - Edit TIN',
    }
  },
  {
    path: '/settings/security/pin',
    name: 'update-security-pin-page',
    component: UpdatePinSettingView,
    meta: {
      title: 'PAPSI Mobile - Edit PIN',
    }
  },
  {
    path: '/user/enroll-pin',
    name: 'enroll-pin-page',
    component: EnrollPinView,
    meta: {
      title: 'PAPSI Mobile - Enroll PIN',
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundView,
    meta: {
      title: 'Not Found',
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
})

export default router
