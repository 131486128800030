<template>
    <div>
        <ContentWrapperControl>
            <div class="bg-white p-4 rounded-b shadow mb-4">
                <div v-text="lang('lbl_0000012')" class="text-sm text-grey-darkest leading-normal"></div>
            </div>
            <div class="bg-white p-4 rounded shadow">
                <div class="mb-3">
                    <TextboxControl @typing="typing" 
                        v-bind:value="value"
                        name="info" />
                </div>
                <div>
                    <ButtonControl v-on:click="find" v-bind:disabled="disabled">
                        {{ lang('lbl_0000004') }}
                    </ButtonControl>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import TextboxControl from './../../components/controls/TextControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        TextboxControl,
    },
    data() {
        return {
            disabled: true,
            value: '',
        }
    },
    mounted() {
        this.$emit('checkauth', false);
        this.$emit('shownavbar', {
            title: this.lang('lbl_0000001'),
            show: true,
            back: true,
        });
    },
    methods: {
        typing(obj) {
            this.value = obj.value;
            this.disabled = obj.value.length == 0;
        },
        find() {
            this.$emit('showloader');
            let that = this;

            User.forgotPassword(this.value).then(function(response) {
                let state = that.$store.state,
                    data = response.data.data;

                state.forgot.value = that.value;
                state.forgot.uuid = data.uuid;
                state.forgot.hash = data.hash;
                state.forgot.pin = data.pin;
                state.forgot.username = data.username;
                state.forgot.first_name = data.first_name;
                state.forgot.last_name = data.last_name;
                state.forgot.email = data.email;
                state.forgot.mobile = data.mobile_no;

                that.$emit('hideloader');
                that.$router.push('/forgot-password/verify');
            }).catch(function(error) {
                that.value = '';
                that.$emit('error', error);
            });
        },
    },
}
</script>