<template>
  <div class="w-full h-screen max-h-screen bg-primary flex flex-col select-none papsi-main-wrapper">
    <div v-if="navbar.show && !maintenance && !loading">
      <NavbarSection @opensidebar="openSidebar"
        v-bind:noback="!navbar.back"
        v-bind:type="navbar.type"
        v-bind:menu="navbar.menu" 
        v-bind:title="navbar.title" />
    </div>
    <div ref="main_container" class="flex-grow bg-grey-lighter overflow-y-auto overflow-x-hidden">
      <div v-if="maintenance">
        <MaintenanceModeSection />
      </div>
      <div v-if="!maintenance">
        <div v-show="blinder" class="w-full h-screen fixed z-modal-screen papsi-blinder-screen"></div>
        <LoadingScreen v-show="loading" />
        <MessageBoxControl @closed="closeMessageBox"
          v-bind:show="message.show"
          v-bind:theme="message.theme"
          v-bind:title="message.title"
          v-bind:message="message.message" />
        <SidebarSection @closed="closeSidebar" 
          @showloader="showLoader"
          @hideloader="hideLoader"
          @terminate="terminated" 
          @logout="logoutNow"
          v-bind:show="sidebar && login && loaded" />
        <div v-if="loaded" v-show="!loading">
          <router-view @success="success"
            @error="error"
            @alert="alert"
            @login="loginNow"
            @logout="logoutNow"
            @showloader="showLoader" 
            @hideloader="hideLoader"
            @showblinder="showBlinder"
            @hideblinder="hideBlinder"
            @terminate="terminated"
            @checkauth="checkAuth"          
            @nofooter="nofooter" 
            @shownavbar="showNavbar"
            @hidenavbar="hideNavbar"
            @opensidebar="openSidebar" />
        </div>
      </div>
    </div>
    <div v-if="!maintenance && !loading && login && !no_footer">
      <FooterSection />
    </div>
  </div>
</template>

<script>
import { isMobile, isTablet, isWinPhone, isAndroid, isMobileSafari, isIOS, isIOS13, isIPad13, isIPhone13 } from 'mobile-device-detect';
import FooterSection from './components/sections/FooterSection.vue';
import LoadingScreen from './components/sections/LoadingScreenSection.vue';
import MaintenanceModeSection from './components/sections/MaintenanceModeSection.vue';
import MessageBoxControl from './components/controls/MessageBoxControl.vue';
import NavbarSection from './components/sections/NavbarSection.vue';
import SidebarSection from './components/sections/SidebarSection.vue';
import mixin from './mixin.js';
import preload from './preload.js';
import User from './services/User.js';

export default {
  name: 'app',
  mixins: [
    mixin,
    preload,
  ],
  components: {
    FooterSection,
    LoadingScreen,
    MaintenanceModeSection,
    MessageBoxControl,
    NavbarSection,
    SidebarSection,
  },
  data: function() {
    return {
      login: false,
      loading: true,
      loaded: false,
      sidebar: false,
      no_footer: false,
      maintenance: false,
      user_agent: null,
      blinder: false,
      navbar: {
        type: null,
        show: false,
        back: true,
        menu: true,
        title: null,
      },
      message: {
        theme: 'success',
        title: '',
        message: '',
        show: false,
        href: null,
      },
    }
  },
  mounted() {
    let that = this;

    if(isMobile || isTablet || isWinPhone || isAndroid || isMobileSafari || isIOS || isIOS13 || isIPad13 || isIPhone13) {
      this.maintenance = process.env.VUE_APP_MAINTENANCE_MODE.toString() == 'true';

      if(!this.maintenance) {
        this.preloadStart(function() {
          that.preloadingDone();
          that.setUserAgent(navigator.userAgent);
        });
      }
    }
    else {
      window.location.href = process.env.VUE_APP_WEB_URL + '?from=mobile';
    }
  },
  methods: {
    alert(config) {
      this.message.theme = config.theme;
      this.message.title = config.title;
      this.message.message = config.message;
      this.message.href = config.href;
      this.message.show = true;
    },
    closeMessageBox() {
      this.message.show = false;

      if (this.message.href != null) {
        this.$router.push(this.message.href);
      }

      this.message.theme = 'success';
      this.message.title = '';
      this.message.message = '';
      this.message.href = null;
    },
    showNavbar(obj) {
      this.navbar.title = typeof obj.title !== 'undefined' ? obj.title : this.lang('lbl_0000008');
      this.navbar.type = typeof obj.type !== 'undefined' ? obj.type : 'normal';
      this.navbar.back = typeof obj.back !== 'undefined' ? obj.back : true;
      this.navbar.menu = typeof obj.menu !== 'undefined' ? obj.menu : false;
      this.navbar.show = typeof obj.show !== 'undefined' ? obj.show : true;
    },
    hideNavbar() {
      this.navbar.show = false;
    },
    preloadingDone() {
      if(!this.maintenance) {
        this.checkCookies();
      }
      else {
        this.$store.state.down = true;
        this.loading = false;
        this.loaded = true;
      }
    },
    checkAuth(status = true) {
      let state = this.$store.state;

      // if auth is required.
      if(status) {

        // if cookie is not available.
        if(!this.$cookies.isKey('hash')) {
          this.clearCookies();
          this.$router.push('/');
        }
        else {

          // if cookie is available but user is deactivated.
          if (state.deactivated) {
            this.$router.push('/user/deactivated');
          }

          // if cookie is available but user has no pin.
          else if (!state.has_pin) {
            this.$router.push('/user/enroll-pin');
          }
        }
      }

      // if auth is not required.
      else {

        // if cookie is available.
        if(this.$cookies.isKey('hash')) {

          // if cookie is available but user is deactivated.
          if (state.deactivated) {
            this.$router.push('/user/deactivated');
          }

          // if cookie is available but user has no pin.
          else if (!state.has_pin) {
            this.$router.push('/user/enroll-pin');
          }

          // redirects to dashboard.
          else {
            this.$router.push('/dashboard');
          }
        }
      }
    },
    checkCookies() {
      if(this.$cookies.isKey('hash')) {
        let that = this;
            
        User.fetchSession().then(function(response) {
          that.login = true;
          that.setUserData(response.data.data);
          that.hideLoader();
          that.loaded = true;
        }).catch(function() {
          that.clearCookies();
          that.hideLoader();
          that.loaded = true;
          that.resetUserData();
        });
      }
      else {
        this.hideLoader();
        this.loaded = true;
        this.resetUserData();
      }
    },
    clearCookies() {
      if(this.$cookies.isKey('hash')) {
        this.$cookies.remove('hash');
      }
    },
    loginNow() {
      this.loaded = true;
      this.login = true;
      this.sidebar = false;
    },
    logoutNow() {
      this.loaded = true;
      this.login = false;
      this.sidebar = false;
    },
    goToLogin() {
      window.location.href = process.env.VUE_APP_BASE_URL + '?t=' + (new Date().getTime().toString());
    },
    showLoader() {
      this.loading = true;
    },
    hideLoader() {
      this.loading = false;
    },
    showBlinder() {
      this.blinder = true;
    },
    hideBlinder() {
      this.blinder = false;
    },
    openSidebar() {
      this.sidebar = true;
    },
    closeSidebar() {
      this.sidebar = false;
    },
    nofooter(v = true) { 
      this.no_footer = v;
    },
    terminated(status) {
      if(status == 403) {
        let title = this.lang('log.in'),
            message = this.lang('please.login.to.your.account');

        this.hideLoader();
        this.clearCookies();
        this.alert({
          theme: 'error',
          title: title,
          message: message,
        });
      }
    },
    error(error) {
      let response = error.response.data,
          status = response.status;

      if (status == 403) {
        this.logoutNow();
        this.destroyCookie();
        this.resetUserData();
        this.alert({
          theme: 'error',
          title: this.lang('lbl_0000034'),
          message: this.lang('lbl_0000055'),
          href: '/',
        });
      }
      else {
        this.alert({
          theme: 'error',
          title: this.lang('lbl_0000034'),
          message: response.message,
        });
      }
      
      this.hideLoader();
    },
    success(message) {
      this.alert({
        theme: 'success',
        title: this.lang('lbl_0000058'),
        message: message,
      });

      this.hideLoader();
    },
  },
  watch: {
    $route() {
      this.$refs.main_container.scrollTop = 0;
    },
  },
}
</script>

<style>
body {
  width: 100%;
  height: 100vh;
}
.papsi-blinder-screen {
  background-color: rgba(0, 0, 0, 0.85);
  top: 0px;
  left: 0px;
}
.papsi-main-wrapper {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}
</style>